import React from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { IcoX } from "../shared/icons";
import { GET_ALERTS } from "./getAlertsMsgQuery";

// const DELETE_ALERT = gql`
//   mutation deleteAlert($alertId: ID!) {
//     deleteAlert(alertId: $alertId)
//   }
// `;

const MARK_AS_READ = gql`
  mutation markAlertMessageRead($alertMessageId: ID!) {
    markAlertMessageRead(alertMessageId: $alertMessageId) {
      id
      type
      read
    }
  }
`;

const DELETE_ALERT_MESSAGE = gql`
  mutation deleteAlertMessage($alertMessageId: ID!) {
    deleteAlertMessage(alertMessageId: $alertMessageId)
  }
`;

const MarkAsRead = ({ alertId, closeDialog }) => (
  <Mutation mutation={MARK_AS_READ} onCompleted={data => {}}>
    {markAsRead => (
      <button
        onClick={e => {
          markAsRead({ variables: { alertMessageId: alertId } });
          closeDialog();
        }}
        type="button"
        className="alertDialog-action"
      >
        mark as read
      </button>
    )}
  </Mutation>
);
const DeleteAlertMessage = ({ alertId }) => {
  return (
    <Mutation
      mutation={DELETE_ALERT_MESSAGE}
      refetchQueries={() => [{ query: GET_ALERTS }]}
      awaitRefetchQueries={true}
    >
      {(deleteAlertMessage, { loading, error }) => {
        if (loading) return <p>...</p>;
        return (
          <button
            onClick={e =>
              deleteAlertMessage({ variables: { alertMessageId: alertId } })
            }
            type="button"
            className="alertDialog-action"
          >
            delete
          </button>
        );
      }}
    </Mutation>
  );
};

const AlertItemControls = ({ alertId, closeDialog }) => {
  return (
    <div className="alertDialog">
      <div className="alertDialog-top">
        <button onClick={closeDialog} className="alertDialog-close">
          <IcoX />
        </button>
      </div>
      <div className="alertDialog-actions">
        <MarkAsRead alertId={alertId} closeDialog={closeDialog} />
        <DeleteAlertMessage alertId={alertId} />
      </div>
    </div>
  );
};

export default AlertItemControls;
