import React from 'react';
import InfoModal from '../shared/InfoModal';

class HomePageFirstTimeVisit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showWelcome: false
    };
  }

  componentDidMount() {
    if (!localStorage.getItem('firstTimeHome')) {
      this.timerHandle = setTimeout(() => {
        this.setState({ showWelcome: true });
        this.timerHandle = 0;
        localStorage.setItem('firstTimeHome', true);
      }, 3000);
    }
  }

  componentWillUnmount = () => {
    if (this.timerHandle) {
      // Yes, clear it
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  };

  render() {
    return (
      this.state.showWelcome && (
        <InfoModal onHide={(e) => this.setState({ showWelcome: false })} header={<p />}>
          <div style={{ padding: 15 }}>
            <div style={{ textAlign: 'left', lineHeight: '10px' }}>
              <h3 style={{ fontWeight: '600' }}>no algorithms.</h3>
              <h3 style={{ fontWeight: '600' }}>no genres.</h3>
              <h3 style={{ fontWeight: '600' }}>no fees.</h3>
              <h3 style={{ fontWeight: '600' }}>no commitments.</h3>
              <h3 style={{ fontWeight: '600' }}>no corporate backing.</h3>
              <h3 style={{ fontWeight: '600' }}>no regrets.</h3>
            </div>

            <div style={{ textAlign: 'left', lineHeight: '10px' }}>
              <h3 style={{ marginTop: '30px', fontWeight: '600' }}>listeners supporting artists.</h3>
            </div>
            <div style={{ textAlign: 'left', lineHeight: '10px' }}>
              <h3 style={{ marginTop: '30px', marginBottom: 0, fontWeight: '600' }}>pure chaos.</h3>
            </div>
          </div>
        </InfoModal>
      )
    );
  }
}

export default HomePageFirstTimeVisit;
