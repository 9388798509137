import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { AUTH_TOKEN } from "../../../constants";

import RegisterForm from "./RegisterForm";

const SIGNUP = gql`
  mutation LoginMutation(
    $email: EmailAddress!
    $password: String!
    $userName: String
    $firstName: String
    $zip: String
  ) {
    signUp(
      email: $email
      password: $password
      role: User
      userName: $userName
      firstName: $firstName
      zip: $zip
    ) {
      id
      userName
      firstName
      zip
      email
      token
    }
  }
`;

class RegisterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _confirm = async (data) => {
    const { token } = data.signUp;
    this._saveUserData(token);
    this.props.history.replace(`/you`);
  };

  _saveUserData = (token) => {
    sessionStorage.setItem(AUTH_TOKEN, token);
  };

  render() {
    return (
      <div className="loginForm-container">
        <Mutation
          mutation={SIGNUP}
          onCompleted={(data) => {
            this._confirm(data);
          }}
        >
          {(signUp, { loading, error }) => {
            return (
              <RegisterForm
                error={error}
                loading={loading}
                handleSubmit={(submitedData) => {
                  const { email, password, name, userName, zip } = submitedData;
                  let vars = {
                    email,
                    password,
                    userName,
                    firstName: name,
                    zip,
                  };

                  signUp({ variables: vars });
                }}
              />
            );
          }}
        </Mutation>
      </div>
    );
  }
}

export default RegisterContainer;
