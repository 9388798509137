import React from "react";
import { Link } from "react-router-dom";
import { releasePath } from "../../../utils/utils";

const ArchiveItem = props => (
  <div className="sliderItem">
    <Link
      to={props.release ? releasePath(props.release) : "/you/archive"}
      style={{ cursor: props.release ? "pointer" : "auto" }}
    >
      <div
        style={{
          backgroundImage: `url("${props.cover}")`,
          backgroundColor: "#ccc"
        }}
        className="sliderItem-cover"
      >
        <img
          style={{ width: "100px", height: "100px" }}
          src={props.cover}
          alt=""
        />
      </div>
      <div>
        <p className="sliderItem-artist">{props.artist && props.artist}</p>
        <p className="sliderItem-title">
          {props.release && props.release.title}
        </p>
        <p className="sliderItem-type">
          {props.tracks && props.tracks}{" "}
          {props.tracks && (props.tracks === 1 ? "track" : "tracks")}
        </p>
      </div>
    </Link>
  </div>
);

export default ArchiveItem;
