import React, { useContext, useState } from "react";
import PosTrack from "./PosTrack";
import { withRouter } from "react-router-dom";
import PlayerContext from "../../context/PlayerContext";
import { getTrackIndex } from "../../utils/utils";
import InfoModal from "../shared/InfoModal";

const PosTracksList = props => {
  const [showNotAllowed, toggleShowNotAllowed] = useState(false);
  const playerContext = useContext(PlayerContext);
  const {
    playlist,
    currentTrackIndex,
    setPlaylistAndPlay,
    togglePlayer
  } = playerContext;

  const activeTrackId =
    playlist[currentTrackIndex] && playlist[currentTrackIndex].id;

  const isTrackActive = id => id === activeTrackId;

  const handleTrackOnClick = id => {
    if (!props.isAllowed) {
      toggleShowNotAllowed(true);
    } else {
      let handle = window.document.getElementsByClassName(
        "fxfPlayer-handle"
      )[0];
      if (handle) {
        handle.style.marginLeft = 0;
      }
      setPlaylistAndPlay(props.tracks, getTrackIndex(props.tracks, id));
      togglePlayer(true);
    }
  };

  return (
    <div className="posTracks-list">
      {showNotAllowed && (
        <InfoModal onHide={() => toggleShowNotAllowed(false)}>
          this release is not available in your country
        </InfoModal>
      )}

      {props.tracks.map((t, i) => (
        <PosTrack
          onClickTrack={handleTrackOnClick}
          isActive={isTrackActive(t.id)}
          isTerritoryActive={props.isAllowed}
          trackId={props.trackId}
          track={{ ...t, release: props.release }}
          key={t.id}
          num={i}
          {...t}
        />
      ))}
    </div>
  );
};

export default withRouter(PosTracksList);
