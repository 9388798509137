import React from "react";
import { ARCHIVE_STREAMED } from "./archiveQueries";
import { Query } from "react-apollo";
import { LoaderMain } from "../../shared/loaders";
import ArchiveItem from "./ArchiveItem";

import { createStreamedCollection } from "./createCollections";

const ArchiveStreamed = () => {
  return (
    <Query query={ARCHIVE_STREAMED} fetchPolicy={"network-only"}>
      {({ loading, error, data }) => {
        if (loading) return <LoaderMain />;
        if (error) return `Error! ${error.message}`;
        const streamedCollection = createStreamedCollection(
          data.me.playedTracks
        );

        return (
          <div className="userArchive-wrapper">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "50px",
                justifyContent: "center"
              }}
              className="page-container"
            >
              {streamedCollection.map((item, index) => {
                return <ArchiveItem display="grid" key={index} {...item} />;
              })}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default ArchiveStreamed;
