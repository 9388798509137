import React from "react";
import { Link } from "react-router-dom";
import { releasePath, artistPath } from "../../utils/utils";
import moment from "moment";

const DEFAULT_LIST_SIZE = 10;

class NewReleasesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllItems: false
    };

    this.list = React.createRef();
  }

  showAllItems = () => {
    this.setState({ showAllItems: true });
  };

  componentDidMount = () => {
    this.list.current.style.height = `${this.list.current.offsetHeight}px`;
  };

  render() {
    const { releases } = this.props;

    let releasesList = releases;
    if (!this.state.showAllItems)
      releasesList = releases.slice(0, DEFAULT_LIST_SIZE);

    return (
      <div className="newRel-list" ref={this.list}>
        {releasesList
          .filter(r => r.artist !== null) //TODO: Log null values
          .map(r => {
            const displayDate = moment(r.publicationDate)
              .utc()
              .format("MM.DD.YYYY");

            return (
              <div key={r.id} className="newRel-item">
                <div className="newRel-details">
                  <Link to={artistPath(r.artist)}>
                    <span className="artist">{r.artist.userName}</span>
                    <span> | </span>
                  </Link>
                  <Link to={releasePath(r)}>
                    <span className="title">{r.title}</span>
                  </Link>
                  &nbsp;
                  <span className="type">{r.type}</span>
                  <span> | </span>
                  <span className="date">{displayDate}</span>
                  <p className="about">{r.additionalAdminInfo}</p>
                </div>
              </div>
            );
          })}

        {releases.length > DEFAULT_LIST_SIZE && !this.state.showAllItems && (
          <div style={{ width: "100%", textAlign: "right" }}>
            <button className="newRel-show" onClick={this.showAllItems}>
              more
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default NewReleasesList;
