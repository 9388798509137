import React from "react";
import { Route, Switch, Link } from "react-router-dom";
import { LoaderMain } from "../shared/loaders";
import TnCPage from "./TnCPage";

import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { CMS_WEBSITE } from "../../constants";
import draftToHtml from "draftjs-to-html";

// const { termsConditions } = data.getConfig;
// const terms = draftToHtml(JSON.parse(termsConditions));

// return <div dangerouslySetInnerHTML={{ __html: terms }} />;

const GET_DATA = gql`
  query getConfig {
    getConfig {
      termsConditions
      privacy
      copyright
      why
      email
      everyThingElseEmail
      founded
      createdAt
      updatedAt
    }
  }
`;

const GET_HOME = gql`
  query {
    homePageByWeek(weekStart: "2019-04-15") {
      id
      credits
    }
  }
`;

const ContentOutput = props => {
  const data = draftToHtml(JSON.parse(props.content));
  return (
    <div className="rich-txt" dangerouslySetInnerHTML={{ __html: data }} />
  );
};

const UsPageContainer = props => {
  return (
    <div className="labelWrapper">
      <Query query={GET_DATA}>
        {({ loading, error, data }) => {
          if (loading) return <LoaderMain />;
          if (error) return `Error! ${error.message}`;
          const { getConfig } = data;
          return (
            <div
              style={{
                padding: "20px 20px 20px",
                height: "calc(100vh - 76px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
              className="usPage-container us"
            >
              <Switch>
                <Route
                  exact
                  path="/us/terms"
                  render={props => (
                    <TnCPage
                      {...props}
                      termsConditions={getConfig.termsConditions}
                    />
                  )}
                />
                <Route
                  exact
                  path="/us/privacy"
                  render={props => (
                    <ContentOutput content={getConfig.privacy} />
                  )}
                />
                <Route
                  exact
                  path="/us/copyright"
                  render={props => (
                    <ContentOutput content={getConfig.copyright} />
                  )}
                />
                <Route
                  exact
                  path="/us/why"
                  render={props => <ContentOutput content={getConfig.why} />}
                />
                <Route
                  exact
                  path="/us/help"
                  render={props => (
                    <div>
                      <h2>Help</h2>
                      <p>{getConfig.help}</p>
                    </div>
                  )}
                />

                <Route
                  render={prosp => (
                    <>
                      <div>
                        <Link
                          style={{
                            display: "table",
                            marginTop: "25px"
                            // paddingLeft: "10%"
                          }}
                          to="/us/terms"
                        >
                          t&c’s >
                        </Link>
                        <Link
                          style={{
                            display: "table",
                            marginTop: "25px"
                            // paddingLeft: "20%"
                          }}
                          to="/us/privacy"
                        >
                          privacy >
                        </Link>
                        <Link
                          style={{
                            display: "table",
                            marginTop: "25px"
                            // paddingLeft: "15%"
                          }}
                          to="/us/copyright"
                        >
                          {" "}
                          (c) >
                        </Link>
                        <Link
                          style={{
                            display: "table",
                            marginTop: "45px"
                            // paddingLeft: "60%"
                          }}
                          to="/us/why"
                        >
                          why? >
                        </Link>
                        <a
                          style={{
                            display: "table",
                            marginTop: "45px"
                          }}
                          href={CMS_WEBSITE}
                        >
                          artists / labels >
                        </a>

                        <div style={{ marginTop: "50px" }}>
                          <p style={{ color: "#ccc" }}>
                            technical help:{" "}
                            <a href={`mailto:${data.getConfig.email}`}>
                              {data.getConfig.email}
                            </a>
                          </p>
                          <p
                            style={{
                              color: "#ccc",
                              marginTop: "-17px"
                            }}
                          >
                            everything else:{" "}
                            <a
                              href={`mailto:${
                                data.getConfig.everyThingElseEmail
                              }`}
                            >
                              {data.getConfig.everyThingElseEmail}
                            </a>
                          </p>
                        </div>

                        <div style={{ marginTop: "60px" }}>
                          <p style={{ fontSize: "12px", color: "#ccc" }}>
                            featured photo: <br />
                            <Query query={GET_HOME}>
                              {({ data }) => data.homePageByWeek.credits}
                            </Query>
                          </p>
                        </div>
                      </div>
                      <p style={{ textAlign: "right", fontSize: "12px" }}>
                        founded in {data.getConfig.founded}
                      </p>

                      <p
                        style={{
                          marginTop: "40px",
                          textAlign: "center",
                          fontSize: "12px",
                          paddingBottom: "20px"
                        }}
                      >
                        © 2019 the van is a concept, inc.
                      </p>
                    </>
                  )}
                />
              </Switch>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default UsPageContainer;
