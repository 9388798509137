import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import PlayerContext from "../../context/PlayerContext";
// import bg from "../../assets/images/bg1.png";

const ContentWrapper = ({ location, children }) => {
  const context = useContext(PlayerContext);
  const isPlayerExpanded = context.playerFull;
  const isHomepage = location.pathname === "/";
  const isCredits = location.pathname.includes("/credits");

  const wrapperStyles = () => {
    let style = {};
    if (isHomepage) {
      //style.backgroundImage = `url('${bg}')`;
    }
    if (isCredits) {
      style.backgroundColor = "#000";
      style.height = "100vh";
    }
    if (context.showPlayer && !isHomepage) {
      style.paddingBottom = "100px";
    }
    if (isPlayerExpanded) {
      style.height = "100vh";
      style.overflow = "hidden";
    }
    return style;
  };

  const wrapperClasses = () => {
    let classes = ["appContent-wrapper"];

    if (context.showPlayer) {
      classes = [...classes, "playerActive"];
    }

    return classes.join(" ");
  };

  return (
    <div style={wrapperStyles()} className={wrapperClasses()}>
      {children}
    </div>
  );
};

export default withRouter(ContentWrapper);
