import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Query, Mutation } from "react-apollo";
import { gql } from "apollo-boost";

import dotBg from "../../assets/images/dottedbg.png";
import { IcoX } from "../shared/icons";

import UserDetails from "./userDetails";
import { LoaderMain } from "../shared/loaders";
import UserContext from "../../context/UserContext";
import ResendVerification from "../user/resendVerification/ResendVerification";
// import InfoModal from "../shared/InfoModal";

const USER = gql`
  query me {
    me {
      id
      frontendId
      userName
      firstName
      email
      zip
      supportTier
      supportTotal
      verified
      alertMessage {
        id
        read
        release {
          id
        }
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

class UserDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDelete: false
    };
  }

  static contextType = UserContext;

  showDelete = () => this.setState({ showDelete: true });
  hideDelete = () => this.setState({ showDelete: false });

  unredadedMsgs = messages => {
    return messages.filter(m => !m.read).filter(ml => ml.release !== null)
      .length;
  };
  render() {
    const { props } = this;
    return (
      <Query fetchPolicy="network-only" query={USER}>
        {({ loading, error, data }) => {
          if (loading) return <LoaderMain />;
          if (error) return <Redirect to="/" />;

          if (!data.me.verified)
            return (
              <div className="userVerify">
                <div
                  style={{ padding: "20px", margin: "auto" }}
                  className="verifyMessage-container"
                >
                  <h3>please verify your account</h3>
                  <p> a confirmation email has been sent.</p>
                </div>
                <p>resend verification</p>
                <ResendVerification />
              </div>
            );

          return (
            <div
              style={{
                minHeight: this.context.showPlayer
                  ? "calc(100vh - 170px)"
                  : "calc(100vh - 76px)"
              }}
              className={`userPage-container`}
            >
              <div className="userPage-links">
                <Link to="/you/alerts">
                  alerts ({this.unredadedMsgs(data.me.alertMessage)}) >{" "}
                </Link>
                <Link to="/you/archive">archive > </Link>
              </div>

              <UserDetails user={data.me} />

              <div className="userSupport-container">
                <form action="" className="userSupport-form" />
              </div>

              <div className="userPage-bottom">
                <button onClick={props.logout}>log out x</button>

                <button onClick={this.showDelete} style={{ color: "red" }}>
                  delete account
                </button>

                {this.state.showDelete && (
                  <div
                    style={{ backgroundImage: `url("${dotBg}")` }}
                    className="alert-wrapper"
                  >
                    <div className="alert-backdrop">
                      <div className="alert">
                        <div className="alert-header">
                          <p className="alert-title">delete account</p>
                          <button
                            onClick={this.hideDelete}
                            className="close-modal"
                          >
                            <IcoX />
                          </button>
                        </div>

                        <div className="alert-body">
                          <p>are you sure?</p>
                        </div>
                        <div
                          style={{ textAlign: "center" }}
                          className="alert-footer"
                        >
                          <Mutation
                            mutation={DELETE_USER}
                            onCompleted={data => {
                              props.logout();
                            }}
                          >
                            {(deleteUser, { loading, error }) => (
                              <div>
                                {loading && <p>...</p>}
                                {error && <p>Error :( Please try again</p>}
                                <button
                                  onClick={e =>
                                    deleteUser({
                                      variables: { userId: data.me.id }
                                    })
                                  }
                                  className="btn-danger"
                                >
                                  delete my account
                                </button>
                              </div>
                            )}
                          </Mutation>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default UserDetailsContainer;
