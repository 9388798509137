import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ApolloConsumer } from "react-apollo";
import { gql } from "apollo-boost";

const RESET_PASS = gql`
  query resetPassword($email: EmailAddress!) {
    resetPassword(email: $email)
  }
`;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      succes: null
    };
  }
  render() {
    return (
      <div
        style={{ padding: "30px 10px 0" }}
        className="passwordReset-container"
      >
        <ApolloConsumer>
          {client => (
            <Formik
              initialValues={{
                email: ""
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().required("required")
              })}
              onSubmit={async values => {
                this.setState({ error: null });

                const queryResult = await client
                  .query({
                    query: RESET_PASS,
                    variables: { email: values.email },
                    fetchPolicy: "network-only"
                  })
                  .catch(({ graphQLErrors }) => {
                    if (graphQLErrors[0].message) {
                      this.setState({ error: graphQLErrors[0].message });
                    } else {
                      this.setState({
                        error: "something went wrong, please try again later..."
                      });
                    }
                  });

                if (queryResult) {
                  this.setState({
                    succes: `reset email sent to ${values.email}`
                  });
                }
              }}
              render={() => {
                return (
                  <Form className="loginForm">
                    <div className="formRow">
                      <label className="formLabel" htmlFor="email">
                        email
                      </label>
                      <Field name="email" type="email" />
                      <ErrorMessage
                        name="email"
                        className="formError"
                        component="div"
                      />
                      {this.state.error ? (
                        <div className="gqlError">
                          <p style={{ color: "red" }}>{this.state.error}</p>
                        </div>
                      ) : null}
                      {this.state.succes ? (
                        <div className="gqlError">
                          <p>{this.state.succes}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="formCol">
                      {!this.state.succes && (
                        <button type="submit" className="formBtn">
                          reset password >
                        </button>
                      )}
                    </div>
                  </Form>
                );
              }}
            />
          )}
        </ApolloConsumer>
      </div>
    );
  }
}

export default ResetPassword;
