import React from "react";
import InfoModal from "../shared/InfoModal";
import { Link } from "react-router-dom";
import PaypalBtns from "../paypal/PaypalBtns";

class PosPageFirstTimeVisit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showWelcome: false,
      showSupportModal: false,
      supportValue: 10,
      isSupported: false,
    };
  }

  componentDidMount() {
    const supportValue = this.props.supportTier ? this.props.supportTier : this.state.supportValue;
    this.setState({ supportValue });
  }

  handleChangeSupported = () => this.setState({ isSupported: !this.state.isSupported });

  render() {
    const { supportValue, isSupported } = this.state;
    return (
      <React.Fragment>
        {localStorage.getItem("firstSupport") ? (
          <React.Fragment>
            <button
              onClick={(e) => this.setState({ showSupportModal: true })}
              className="pos-btn support-btn"
            >
              support $
            </button>
            {this.state.showSupportModal && (
              <InfoModal
                header={<p
                  style={{
                    fontSize: "20px",
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                >
                  {isSupported ? 'supported' : 'support'} {this.props.username}
                </p>}
                onHide={(e) => this.setState({ showSupportModal: false, isSupported: false })}
              >
                <div>
                  <span>USD $ </span>
                  <input
                    onChange={(e) =>
                      this.setState({ supportValue: e.target.value })
                    }
                    value={supportValue}
                    type="number"
                    min={1}
                  />
                </div>
                <button
                  style={{
                    width: "100%",
                    textAlign: "right",
                    marginTop: "25px",
                    paddingBottom: "30px",
                  }}
                >
                  <PaypalBtns
                    merchantId={this.props.merchantId}
                    supportVal={supportValue}
                    isSupport={true}
                    handleChangeSupported={this.handleChangeSupported}
                  />
                </button>
                <hr />
                <p style={{ fontSize: "12px" }}>
                  support payments are remitted to artist. information on
                  revenue share {` `}
                  <Link target="_blank" to="/us/terms">
                    <span
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      here
                    </span>
                    .
                  </Link>
                </p>
              </InfoModal>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <button
              onClick={(e) => this.setState({ showWelcome: true })}
              className="pos-btn support-btn"
            >
              support $
            </button>

            {this.state.showWelcome && (
              <InfoModal
                onHide={(e) => {
                  this.setState({ showWelcome: false });
                  localStorage.setItem("firstSupport", true);
                }}
                header={<p>support artist</p>}
              >
                <p>
                  compensate this artist with a custom dollar amount, without
                  future commitment, without an exchange of goods. a preset
                  support tier can be selected in profile settings, to make
                  supporting artists more efficient and habit-forming.
                </p>

                <p>
                  U.S. users signed in to their Venmo will see a Venmo payment option when accessing the mobile site via iPhone/Safari, or Android/Chrome.
                </p>
              </InfoModal>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default PosPageFirstTimeVisit;
