import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";

import { IcoX } from "../shared/icons";
import SearchSuggestions from "./SearchSuggestions";
import SearchDetailsContainer from "./SearchDetailsContainer";

class SearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFor: "",
      showSuggest: false
    };
    this.searchInput = React.createRef();
  }

  componentDidMount() {
    this.searchInput.current.focus();
  }

  handleSearch = e => {
    this.setState({ searchFor: e.target.value });
  };

  hideSuggest = () => {
    setTimeout(() => this.setState({ showSuggest: false }), 500);
  };

  render() {
    return (
      <div className="search-wrapper">
        <div className="searchBar">
          <form
            onSubmit={e => {
              e.preventDefault();
              this.searchInput.current.blur();
              this.props.history.replace(
                `/search/details?q=${this.searchInput.current.value}`
              );
            }}
          >
            <DebounceInput
              className="searchBar-input"
              minLength={3}
              debounceTimeout={400}
              onChange={this.handleSearch}
              onFocus={e => this.setState({ showSuggest: true })}
              onBlur={this.hideSuggest}
              placeholder=""
              inputRef={this.searchInput}
            />
          </form>
          <button
            onClick={e => this.props.history.goBack()}
            className="searchBar-close"
          >
            <IcoX />
          </button>
        </div>

        {this.state.showSuggest && (
          <SearchSuggestions search={this.state.searchFor} />
        )}

        <Switch>
          <Route
            exact
            path="/search/details"
            render={props => <SearchDetailsContainer {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default SearchContainer;
