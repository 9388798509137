import React from "react";
import InfoModal from "../shared/InfoModal";
import { Link } from "react-router-dom";
import PaypalBtns from "../paypal/PaypalBtns";

const PurchaseTrackModal = props => {
  const { title, release, price } = props;
  const [selectedPrice, setPrice] = React.useState(props.price);

  const getMerchantId = () => {
    if (release.label) {
      return release.label.id;
    } else {
      return release.artist.id;
    }
  };
  return (
    <InfoModal
      header={
        <p
          style={{
            fontSize: "20px",
            width: "100%",
            textAlign: "right",
            paddingRight: "20px"
          }}
        >
          purchase track
        </p>
      }
      onHide={e => props.tooglePurchaseModal(false)}
    >
      <div style={{ padding: "10px" }} className="purchaseRelease">
        <p style={{ fontSize: "20px" }}>{title} | </p>
        <p style={{ fontSize: "20px", color: "#ccc" }}>
          {release.artist.userName} | {release.title}
        </p>
        <div style={{ padding: "20px" }}>
          <span>USD $ </span>
          <input
            onChange={e => {
              let reg = new RegExp("^\\d+$");
              if (reg.test(e.target.value)) {
                setPrice(e.target.value);
              }
            }}
            value={selectedPrice}
            type="number"
            min={price}
          />
        </div>

        <PaypalBtns
          isTrack={true}
          price={selectedPrice}
          merchantId={getMerchantId()}
          trackId={props.id}
          releaseId={props.release.id}
        />

        <hr />
        <p style={{ fontSize: "12px" }}>
          purchase payments are remitted to provider of content. you can pay the
          provider’s asking price, or more. information on revenue share{" "}
          <Link
            target="_blank"
            style={{ textDecoration: "underline" }}
            to="/us/terms"
          >
            <span style={{ fontWeight: "bolder" }}>here</span>
          </Link>
        </p>
      </div>
    </InfoModal>
  );
};

export default PurchaseTrackModal;
