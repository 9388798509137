import React from "react";

const DeletedMessage = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <p>
        your account has been deleted by the admin. <br /> contact
        admin1@thevan.fail with any questions.
      </p>
    </div>
  );
};

export default DeletedMessage;
