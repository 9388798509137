import React from "react";
import ReactDOM from "react-dom";
import dotBg from "../../assets/images/dottedbg.png";
import { IcoX } from "../shared/icons";

const modalRoot = document.getElementById("modal-root");

class InfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  el = document.createElement("div");

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    const { onHide } = this.props;
    return ReactDOM.createPortal(
      <div
        style={{ backgroundImage: `url("${dotBg}")` }}
        className="alert-wrapper"
      >
        <div className="alert-backdrop">
          <div className="alert">
            <div className="alert-header">
              <div>{this.props.header}</div>

              <button onClick={onHide} className="close-modal">
                <IcoX />
              </button>
            </div>

            <div className="alert-body">
              {this.props.body}
              {this.props.children}
            </div>
          </div>
        </div>{" "}
      </div>,
      this.el
    );
  }
}

export default InfoModal;
