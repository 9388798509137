import React from "react";
import { Link } from "react-router-dom";
import { releasePath } from "../../utils/utils";
import moment from "moment";

const LabelList = props => {
  const { releases } = props;

  const sortedReleases = releases
    .sort((a, b) => {
      const a1 = new Date(a.publicationDate);
      const a2 = new Date(b.publicationDate);

      return a1 > a2 ? -1 : 1;
    })
    .filter(r => r.status === "Published")
    .filter(r => {
      if (!r.artist) {
      }
      return r.artist;
    });

  return (
    <div className="labelList-wrapper">
      <div className="labelList-grid">
        {sortedReleases.map(r => {
          const { id, title, cover, type, publicationDate } = r;
          const releaseDate = moment(publicationDate)
            .utc()
            .format("MM.DD.YYYY");

          return (
            <Link to={releasePath(r)} key={id} className="labelListItem">
              <img src={cover} alt="" className="labelListItem-cover" />

              <p className="labelListItem-title">{title}</p>
              <p className="labelListItem-type">{type.toLowerCase()}</p>
              <p className="labelListItem-artist"> {releaseDate}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default LabelList;
