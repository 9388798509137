import React from "react";
import InfoModal from "../shared/InfoModal";

const ArchivePopUp = ({ onClose }) => {
  if (localStorage.getItem("firstArchive")) return null;

  return (
    <InfoModal
      onHide={e => {
        onClose();
        localStorage.setItem("firstArchive", true);
      }}
    >
      <p>
        tap or click to save & share a photo of a supported artist. <br />{" "}
        (suggested text: "i support @artist @thevanwtf")
      </p>
    </InfoModal>
  );
};

export default ArchivePopUp;
