import React from "react";
import { Link } from "react-router-dom";
import { artistPath, releasePath } from "../../utils/utils";
import moment from "moment";

const DEFAULT_LIST_SIZE = 10;

class NewTracksList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllItems: false
    };

    this.list = React.createRef();
  }

  showAllItems = () => {
    this.setState({ showAllItems: true });
  };

  componentDidMount = () => {
    this.list.current.style.height = `${this.list.current.offsetHeight}px`;
  };

  render() {
    const { tracks } = this.props;

    let tracksList = tracks;
    if (!this.state.showAllItems)
      tracksList = tracks.slice(0, DEFAULT_LIST_SIZE);

    return (
      <div className="newRel-list" ref={this.list}>
        {tracksList
          .filter(t => t.release.artist !== null) //TODO: Log null values
          .map(t => {
            const displayDate = moment(t.publicationDate)
              .utc()
              .format("MM.DD.YYYY");
            return (
              <div key={t.id} className="newRel-item">
                <div className="newRel-details">
                  <Link to={artistPath(t.release.artist)}>
                    <span className="artist"> {t.artist}</span>
                    <span> | </span>
                  </Link>
                  <Link
                    to={`${releasePath(t.release)}?track=${t.id}&start=true`}
                  >
                    <span className="title">{t.title}</span>
                    <span> | </span>
                  </Link>
                  <Link to={releasePath(t.release)}>
                    <span className="release">{t.release.title}</span>
                  </Link>
                  &nbsp;
                  <span className="type">{t.release.type}</span>
                  <span> | </span>
                  <span className="date">{displayDate}</span>
                  <p className="about">{t.additionalAdminInfo}</p>
                </div>
              </div>
            );
          })}

        {tracks.length > DEFAULT_LIST_SIZE && !this.state.showAllItems && (
          <div style={{ width: "100%", textAlign: "right" }}>
            <button className="newRel-show" onClick={this.showAllItems}>
              more
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default NewTracksList;
