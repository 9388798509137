import React from "react";
import { NavLink } from "react-router-dom";

import { Query } from "react-apollo";

import SEARCH from "./searchQuery";

const SearchSuggestions = props => {
  const { search } = props;
  return (
    <div className="searchSuggestions">
      {search.length >= 3 && (
        <Query query={SEARCH} variables={{ searchText: search }}>
          {({ loading, error, data }) => {
            if (loading) return "";
            if (error) return `Error! ${error.message}`;

            const { artists, releases, tracks } = data.appSearch;
            const result = [...artists, ...releases, ...tracks];

            return (
              <div className="searchSuggestions-list">
                {result.length ? (
                  result.map(res => {
                    if (res.userName) {
                      return (
                        <NavLink
                          replace
                          key={res.id}
                          to={`/search/details?q=${res.userName}`}
                          className="searchSuggestions-item"
                        >
                          {res.userName}
                        </NavLink>
                      );
                    } else {
                      return (
                        <NavLink
                          replace
                          key={res.id}
                          to={`/search/details?q=${res.title}`}
                          className="searchSuggestions-item"
                        >
                          {res.title}
                        </NavLink>
                      );
                    }
                  })
                ) : (
                  <p>no results for {search}...</p>
                )}
              </div>
            );
          }}
        </Query>
      )}
    </div>
  );
};

export default SearchSuggestions;
