import React, { Component } from "react";
import { Link } from "react-router-dom";
import { releasePath } from "../../utils/utils";

import ArchivePopUp from "../userpage/ArchivePopUp";

const SliderItem = props => (
  <div className="sliderItem">
    <Link
      to={props.release ? releasePath(props.release) : "/you/archive"}
      style={{ cursor: props.release ? "pointer" : "auto" }}
    >
      <div
        style={{ backgroundImage: `url("${props.cover}")` }}
        className="sliderItem-cover"
      >
        <img
          style={{ width: "100px", height: "100px" }}
          src={props.cover}
          alt=""
        />
      </div>
      <p className="sliderItem-artist">{props.artist && props.artist}</p>
      <p className="sliderItem-title">{props.release && props.release.title}</p>
      <p className="sliderItem-type">
        {props.tracks && props.tracks}{" "}
        {props.tracks && (props.tracks === 1 ? "track" : "tracks")}
      </p>
    </Link>
  </div>
);

const EmptySliderItem = () => (
  <div
    style={{ width: "100px", height: "100px", border: "1px dashed #5a5a5acc" }}
    className="sliderItem empty"
  />
);

class VeritcalSlider extends Component {
  constructor(props) {
    super(props);
      this.scrollRef = React.createRef()
      this.state = { showOverlay: this.props.withPopUp };
  }
    scrollLeft=e=>{
        let element = this.scrollRef.current
        element.scrollLeft = element.scrollLeft - 200
    }
    scrollRight=e=>{
        let element = this.scrollRef.current
        element.scrollLeft = element.scrollLeft + 200
    }
  render() {
    return (
      <div className="slider-container" style={{ position: "relative" }}>
          <div className="scroll">
              <span className="scroll-left"
                    onClick={this.scrollLeft}
              > &lt;</span> &nbsp; <span className="scroll-right"
                                         onClick={this.scrollRight}
          >&gt;</span>
          </div>
        {this.state.showOverlay && (
          <ArchivePopUp onClose={e => this.setState({ showOverlay: false })} />
        )}
        <div className="slider-inner" ref={this.scrollRef}>
          {this.props.collection ? (
            this.props.collection.map(item => {
              return <SliderItem key={item.id} {...item} />;
            })
          ) : (
            <EmptySliderItem />
          )}
        </div>
      </div>
    );
  }
}

export default VeritcalSlider;
