import React from "react";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import SetAlerts from "./SetAlerts";
import { GET_ALERTS } from "./alertsQueries";

const AlertsControls = props => {
  const artistId = props.match.params.artistId;

  const getActiveAlert = (alerts, type) => {
    const alert = alerts
      .filter(alert => alert.artist !== null)
      .filter(alert => alert.type === type && alert.artist.id === artistId)
      .map(alert => ({ artistId: alert.artist.id, alertId: alert.id }))[0];

    return alert || null;
  };

  return (
    <Query query={GET_ALERTS}>
      {({ loading, error, data, refetch }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        const alerts = data.getAlerts;

        const releasesAlerts = getActiveAlert(alerts, "Release");
        const trackAlerts = getActiveAlert(alerts, "Track");

        return (
          <SetAlerts
            releaseAlert={releasesAlerts}
            trackAlert={trackAlerts}
            refetch={refetch}
            {...props}
          />
        );
      }}
    </Query>
  );
};

export default withRouter(AlertsControls);
