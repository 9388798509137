import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";

import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import { LoaderMain } from "../shared/loaders";
import ArtistHeader from "./ArtistHeader";
import LabelList from "../label/LabelList";
import { artistPath } from "../../utils/utils";

const GET_ARTIST = gql`
  query appLabelArtistDetail($id: ID!) {
    appLabelArtistDetail(id: $id) {
      id
      userName
      firstName
      lastName
      picture
      about
      paypal {
        merchantIdInPayPal
      }
      city
      state
      country
      preReleases {
        id
        title
        publicationDate
        status
        cover
        type
        artist {
          id
          userName
          firstName
          lastName
        }
      }
      release {
        id
        title
        publicationDate
        status
        cover
        type
        artist {
          id
          userName
          firstName
          lastName
        }
      }
    }
  }
`;

class ArtistPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const artistId = this.props.match.params.artistId;

    return (
      <div className="artistWrapper">
        <Query query={GET_ARTIST} variables={{ id: artistId }}>
          {({ loading, error, data }) => {
            if (loading) return <LoaderMain />;
            if (error) return `Error! ${error.message}`;

            const { release, preReleases, about } = data.appLabelArtistDetail;
            return (
              <div className="artistContainer">
                <ArtistHeader {...data.appLabelArtistDetail} />

                <div className="artist-content">
                  <div className="artist-links">
                    <NavLink
                      exact
                      replace
                      to={artistPath(data.appLabelArtistDetail)}
                      className="labelList-btn"
                      activeClassName="active"
                    >
                      releases
                    </NavLink>

                    <NavLink
                      replace
                      to={`${artistPath(data.appLabelArtistDetail)}/bio`}
                      className="labelList-btn"
                      activeClassName="active"
                    >
                      bio
                    </NavLink>
                  </div>

                  <Switch>
                    <Route
                      path={`${artistPath(data.appLabelArtistDetail)}/bio`}
                      render={props => {
                        return <p> {about} </p>;
                      }}
                    />
                    <Route
                      render={props => {
                        return (
                          <LabelList releases={[...release, ...preReleases]} />
                        );
                      }}
                    />
                  </Switch>
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ArtistPageContainer;
