import React from "react";
import { ARCHIVE_SUPPORTED } from "./archiveQueries";
import { Query } from "react-apollo";
import { LoaderMain } from "../../shared/loaders";
// import ArchiveItem from "./ArchiveItem";
import ArchiveItemArtist from "./ArchiveItemArtist";

const ArchiveSupported = () => {
  return (
    <Query query={ARCHIVE_SUPPORTED} fetchPolicy={"network-only"}>
      {({ loading, error, data }) => {
        if (loading) return <LoaderMain />;
        if (error) return `Error! ${error.message}`;
        return (
          <div className="userArchive-wrapper">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "50px",
                justifyContent: "center"
              }}
              className="page-container"
            >
              {data.me.support &&
                data.me.support.map((item, index) => {
                  return (
                    <ArchiveItemArtist
                      display="grid"
                      key={index}
                      {...item.paymentReceiver}
                    />
                  );
                })}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default ArchiveSupported;
