import React, { useRef } from "react";
// import Hls from "hls.js";

const HlsTest = () => {
  const audio = useRef();

  // const prom = audios =>
  //   new Promise(resolve => {
  //     setTimeout(() => resolve(), 1000);
  //   });

  const rev = () => {
    audio.current.currentTime = audio.current.duration - 3;
  };
  const trackProgress = () => console.log("");
  const playAudio = async () => {
    audio.current.src =
      "https://van-api.herokuapp.com/hls/playback/5d64b41c89382f5c66ef51d0/source";

    // await prom();
    audio.current.play();
    audio.current.addEventListener("timeupdate", trackProgress, true);

    audio.current.addEventListener("ended", () => {
      audio.current.src =
        "https://van-api.herokuapp.com/hls/playback/5d793edd2669cc6486a337df/source";
      audio.current.play();
    });
  };

  return (
    <div>
      <h1 style={{ margin: "20%" }}>Hls test</h1>
      <button className="btn" onClick={playAudio}>
        Play
      </button>
      <button className="btn" onClick={rev}>
        Rev
      </button>

      <audio ref={audio} src="" />
    </div>
  );
};

export default HlsTest;
