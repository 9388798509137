import React from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";

import UserDetailsForm from "./UserDetailsForm";

const USER = gql`
  query me {
    me {
      id
      frontendId
      userName
      firstName
      email
      zip
      supportTier
      supportTotal
    }
  }
`;

const UPDATE_DETAILS = gql`
  mutation EditUserMutation(
    $userName: String
    $firstName: String
    $email: EmailAddress
    $zip: String
    $supportTier: Float
  ) {
    editUser(
      userName: $userName
      firstName: $firstName
      email: $email
      zip: $zip
      supportTier: $supportTier
    ) {
      id
      userName
      firstName
      email
      zip
      supportTier
      supportTotal
    }
  }
`;

const UserDetails = props => {
  return (
    <div className="userDetails-container">
      <div className="userDetailsForm-container">
        <Mutation
          mutation={UPDATE_DETAILS}
          refetchQueries={() => [{ query: USER }]}
        >
          {(edit, { loading, error }) => (
            <UserDetailsForm
              user={props.user}
              loading={loading}
              error={error}
              onSubmit={data => {
                edit({ variables: { ...data } });
              }}
            />
          )}
        </Mutation>
      </div>
    </div>
  );
};

export default UserDetails;
