import React from "react";
import { Switch, Route } from "react-router-dom";
import ArchiveStreamed from "./ArchiveStreamed";
import ArchiveMain from "./ArchiveMain";
import ArchiveSupported from "./ArchiveSupported";
import ArchivePurchased from "./ArchivePurchased";

const UserArchiveContainer = () => {
  return (
    <Switch>
      <Route exact path={"/you/archive/streamed"} component={ArchiveStreamed} />
      <Route
        exact
        path={"/you/archive/purchased"}
        component={ArchivePurchased}
      />
      <Route
        exact
        path={"/you/archive/supported"}
        component={ArchiveSupported}
      />
      <Route path={"/you/archive"} component={ArchiveMain} />
    </Switch>
  );
};

export default UserArchiveContainer;
