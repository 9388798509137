import React from 'react';
import { withApollo } from 'react-apollo';
import UserContext from './context/UserContext';
import PlayerContext from './context/PlayerContext';

import TopbarWithSearch from './components/shared/TopBarWithSearch';

import MainRoutes from './components/routes/MainRoutes';
import ContentWrapper from './components/layout/ContentWrapper';
import AppPlayerContainer from './components/appPlayer/AppPlayerContainer';
import ScrollRestore from './components/routes/ScrollRestore';
import { Query } from 'react-apollo';
import USER from './queries/user';
import LogTrackContainer from './components/playback/LogPlayedTrack';
import Player from './components/player/Player';
import { getToken } from './utils/Auth';
import DeletedUserSubscription from './components/subscriptions/DeletedUserSubscription';
import CookiesContainer from './components/cookies/Cookies';
import { /* trackSrcBase, */ hlsSource } from './constants';
import Hls from 'hls.js';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTrackIndex: '',
      playlist: [],
      isLoggedIn: false,
      showPlayer: false,
      isPlaying: false,
      playbackPaused: true,
      track: null,
      me: null,
      logTrack: false,
      playerFull: false,
      isFxfActive: false
    };

    this.track = React.createRef();
    this.hls = new Hls();
  }

  async componentDidMount() {
    const token = await getToken();
    if (token) {
      this.login();
    }
    this.setState({ track: this.track.current });
  }

  setTrackIndex(trackIndex, playlist, rev = false) {
    let i = trackIndex;
    const today = new Date(Date.now());
    const trackDate = playlist[trackIndex] ? new Date(playlist[trackIndex].publicationDate) : 0;
    if (!trackDate) return 0;
    if (today < trackDate) {
      const next = trackIndex + 1 === playlist.length ? 0 : trackIndex + 1;
      const prev = trackIndex <= 0 ? playlist.length - 1 : trackIndex - 1;
      return this.setTrackIndex(rev ? prev : next, playlist, rev);
    } else {
      return i;
    }
  }

  setUser = (me) => this.setState({ me });
  login = () => this.setState({ isLoggedIn: true });
  logout = () => {
    this.pause();
    this.setState({ isLoggedIn: false, me: null, showPlayer: false }, () => {
      this.props.client.clearStore();
    });
  };

  trackReadyToPlay = () => {
    console.timeEnd('play');
  };
  trackEnded = () => {
    const nti = this.setTrackIndex(this.state.currentTrackIndex + 1, this.state.playlist);
    if (!nti) {
      this.setState(
        {
          currentTrackIndex: 0,
          playbackPaused: true
        },
        () => {
          this.setPlaylistAndPlay(undefined, 0, undefined, undefined, false, true);
        }
      );
    } else {
      this.playNextTrack();
    }
  };

  initStream = (playlist, validTrackIndex) =>
    new Promise((resolve, reject) => {
      if (playlist[validTrackIndex].awsKey) {
        this.track.current.src = `${process.env.REACT_APP_S3_CDN_URL}/${playlist[validTrackIndex].awsKey}`;
        resolve();
      } else {
        if (Hls.isSupported()) {
          console.log('if ');
          this.hls.loadSource(
            `${process.env.REACT_APP_HLS_SOURCE}${playlist[validTrackIndex].videoId}/source`
          );

          this.hls.attachMedia(this.track.current);
          this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
            resolve();
          });
        } else if (this.track.current.canPlayType('application/vnd.apple.mpegurl')) {
          console.log(' else if ');
          this.track.current.src = `${process.env.REACT_APP_HLS_SOURCE}${playlist[validTrackIndex]
            .videoId}/source`;
          resolve();
        }
      }
    });

  setPlaylistAndPlay = async (
    playlist = this.state.playlist,
    currentTrackIndex = 0,
    prefetchNextSong = false,
    activateFxf = false,
    play = true,
    withPause = false
  ) => {
    console.time('play');
    this.hls.destroy();
    this.hls = new Hls();

    this.track.current.removeEventListener('ended', this.trackEnded, true);
    this.track.current.removeEventListener('ended', this.trackReadyToPlay, true);

    const validTrackIndex = this.setTrackIndex(currentTrackIndex, playlist);

    this.setState(
      {
        playlist: playlist,
        currentTrackIndex: validTrackIndex,
        isPlaying: true,
        playbackPaused: withPause,
        isFxfActive: activateFxf
      },
      async () => {
        await this.initStream(playlist, validTrackIndex);
        this.track.current.addEventListener('canplay', () => console.timeEnd('play'), true);
        if (play) {
          this.track.current.play();
        } else {
          let hendle = document.getElementsByClassName('fxfPlayer-handle')[0];
          hendle.style.marginLeft = 0;
        }

        this.setState({ logTrack: true });
        this.track.current.addEventListener('ended', this.trackEnded, true);
      }
    );
  };

  playNextTrack = (prefethNext = true) => {
    const { playlist, currentTrackIndex } = this.state;

    const trackToPlayIndex = this.setTrackIndex(currentTrackIndex + 1, playlist);

    this.setState(
      (state) => ({
        currentTrackIndex: trackToPlayIndex,
        playbackPaused: false
      }),
      async () => {
        this.hls.destroy();
        this.hls = new Hls();
        this.track.current.removeEventListener('ended', this.trackEnded, true);

        await this.initStream(this.state.playlist, trackToPlayIndex);
        this.track.current.play();
        this.track.current.addEventListener('ended', this.trackEnded, true);
      }
    );

    this.setState({ logTrack: true });
  };

  playPrevTrack = () => {
    const { playlist, currentTrackIndex } = this.state;
    const trackToPlayIndex = this.setTrackIndex(
      currentTrackIndex === 0 ? playlist.length - 1 : currentTrackIndex - 1,
      playlist,
      true
    );

    this.setState({ currentTrackIndex: trackToPlayIndex, playbackPaused: false }, async () => {
      this.hls.destroy();
      this.hls = new Hls();
      this.track.current.removeEventListener('ended', this.trackEnded, true);

      // this.hls.loadSource(this.track.current.src);

      // this.hls.attachMedia(this.track.current);
      // this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
      //   this.track.current.play();
      //   this.setState({ logTrack: true });
      //   this.track.current.addEventListener("ended", this.trackEnded, true);
      // });

      await this.initStream(playlist, trackToPlayIndex);
      this.track.current.play();
      this.track.current.addEventListener('ended', this.trackEnded, true);
    });

    this.setState({ logTrack: true });
  };

  pause = () => {
    this.setState(
      {
        playbackPaused: true
      },
      () => this.track.current.pause()
    );
  };

  resume = () => {
    this.setState(
      {
        playbackPaused: false
      },
      () => this.track.current.play()
    );
  };

  togglePlayer = (bool) => this.setState({ showPlayer: bool });
  togglePlayerExpandedPlayer = (bool) => this.setState({ playerFull: bool });

  playSelected = (num, activateFxf) => {
    this.setState((state) => ({
      currentTrackNum: num,
      isFxfActive: activateFxf
    }));
  };

  prefetchNextSong = async () => {
    const { playlist, currentTrackIndex } = this.state;
    const isLast = currentTrackIndex === playlist.length - 1;

    const nextTrackUrl = `${hlsSource}${this.state.playlist[isLast ? 0 : currentTrackIndex + 1].videoId}/source`;

    await fetch(nextTrackUrl, { mode: 'no-cors' });
  };

  render() {
    const { playlist, currentTrackIndex } = this.state;
    return (
      <React.Fragment>
        {this.state.isLoggedIn && (
          <Query
            fetchPolicy="network-only"
            query={USER}
            onCompleted={(data) => {
              this.setUser(data.me);
            }}
          >
            {({ loading, error, data, client }) => {
              if (loading) return null;
              if (error) return null;

              const { id } = data.me;
              return <DeletedUserSubscription setUser={this.setUser} id={id} client={client} />;
            }}
          </Query>
        )}

        <audio
          playsInline={true}
          ref={this.track}
          src={
            this.state.playlist && this.state.playlist.length ? (
              `${hlsSource}${this.state.playlist[this.state.currentTrackIndex].videoId}/source`
            ) : (
              ''
            )
          }
        />

        <UserContext.Provider
          value={{
            isLoggedIn: this.state.isLoggedIn,
            login: this.login,
            logout: this.logout,
            showPlayer: this.state.showPlayer,
            setUser: this.setUser,
            me: this.state.me
          }}
        >
          <PlayerContext.Provider
            value={{
              isPlaying: this.state.isPlaying,
              playlist: this.state.playlist,
              currentTrackIndex: this.state.currentTrackIndex,
              setPlaylistAndPlay: this.setPlaylistAndPlay,
              track: this.track.current,
              playNextTrack: this.playNextTrack,
              playPrevTrack: this.playPrevTrack,
              pause: this.pause,
              resume: this.resume,
              playbackPaused: this.state.playbackPaused,
              togglePlayer: this.togglePlayer,
              showPlayer: this.state.showPlayer,
              togglePlayerExpandedPlayer: this.togglePlayerExpandedPlayer,
              playerFull: this.state.playerFull,
              isFxfActive: this.state.isFxfActive
            }}
          >
            <ScrollRestore />
            <TopbarWithSearch />
            <ContentWrapper>
              <MainRoutes />
            </ContentWrapper>
            <AppPlayerContainer
              showPlayer={this.state.showPlayer}
              currentTrackIndex={this.state.currentTrackIndex}
              playlist={this.state.playlist}
            />
            {this.state.playerFull && <Player />}
          </PlayerContext.Provider>
        </UserContext.Provider>

        {this.state.logTrack &&
        this.state.isLoggedIn && (
          <LogTrackContainer
            trackId={playlist ? playlist[currentTrackIndex].id : 440}
            onUnmount={(e) => this.setState({ logTrack: false })}
          />
        )}
        <CookiesContainer />
      </React.Fragment>
    );
  }
}

export default withApollo(App);
