import React, { Component } from "react";
import { DebounceInput } from "react-debounce-input";
// import queryString from "query-string";

import { IcoX } from "../shared/icons";
// import SearchSuggestions from "./SearchSuggestions";
import SearchDetailsContainer from "./SearchDetailsContainer";

class SearchDContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFor: "",
      showSuggest: false
    };
    this.searchInput = React.createRef();
  }

  componentDidMount() {
    this.searchInput.current.focus();
  }

  handleSearch = e => {
    this.setState({ searchFor: e.target.value });
  };

  closeSearch = () => {
    this.setState({ searchFor: "" });
    setTimeout(() => this.props.onClose(), 500);
  };

  hideSuggest = () => {
    setTimeout(() => this.setState({ showSuggest: false }), 500);
  };

  render() {
    // const search = queryString.parse(this.props.location.search).q;

    return (
      <div className="search-wrapper desktop">
        <div className="searchBar">
          <form
            onSubmit={e => {
              e.preventDefault();
              this.searchInput.current.blur();
              this.props.history.replace(
                `?q=${this.searchInput.current.value}`
              );
            }}
          >
            <DebounceInput
              className="searchBar-input"
              minLength={2}
              debounceTimeout={400}
              onChange={this.handleSearch}
              onFocus={e => this.setState({ showSuggest: true })}
              onBlur={this.hideSuggest}
              inputRef={this.searchInput}
            />
          </form>
          <button
            onClick={e => this.props.onClose()}
            className="searchBar-close"
          >
            <IcoX />
          </button>
        </div>

        {/* {this.state.showSuggest && (
          <SearchSuggestions search={this.state.searchFor} />
        )} */}

        <SearchDetailsContainer
          closeSearch={this.closeSearch}
          searchFor={this.state.searchFor}
          {...this.props}
        />
      </div>
    );
  }
}

export default SearchDContainer;
