import React, { Component } from "react";
import { secToMin } from "../../utils/timeFormat";
import PlayerContext from "../../context/PlayerContext";
import PurchaseTrackModal from "./TrackPurchase";
import UserContext from "../../context/UserContext";
import moment from "moment";
import { Download } from "../shared/icons";
import DownloadPurchasedTrack from "../paypal/DownloadPurchasedTrack";

class PosTrack extends Component {
  constructor(props) {
    super(props);
    this.state = { showPurchase: false };
    this.today = new Date(Date.now());
  }

  static contextType = PlayerContext;
  tooglePurchaseModal = (bool) => {
    this.setState({ showPurchase: bool });
  };

  isPublishedByDate = () => {
    const TimeZoneDiff =
      Number(moment(this.props.publicationDate).utcOffset()) + 240;

    const date = moment(new Date()).add(TimeZoneDiff * -1, "minutes");

    const diff = Number(
      moment(new Date(this.props.publicationDate)).diff(date, "minutes")
    );

    return diff - Number(moment(this.props.publicationDate).utcOffset()) > 0;
  };

  render() {
    const { num, title, id, isActive, release } = this.props;

    return (
      <>
        <div className="posTrack-container">
          <button
            onClick={(e) => {
              e.preventDefault();
              if (!this.isPublishedByDate()) this.props.onClickTrack(id);
            }}
          >
            <div
              className={`posTrack ${isActive ? "active" : ""} ${
                this.isPublishedByDate() ? "disabled" : ""
              }`}
            >
              <p>
                <span className="posTrack-num">{num + 1}. </span>
                {title}
              </p>
              <span className="posTrack-duration">
                {secToMin(parseFloat(this.props.duration))}
              </span>
            </div>
          </button>

          {this.props.isTerritoryActive && (
            <UserContext.Consumer>
              {(ctx) => {
                if (!ctx.me || !ctx.me.verified) return null;
                const purchasedTracks = ctx.me.purchasedTracks
                  .filter((purchased) => purchased.track)
                  .map((purchased) => purchased.track.id);
                const purchasedReleases = ctx.me.purchasedReleases
                  ? ctx.me.purchasedReleases.map((r) => r.release.id)
                  : [];
                const isRealeasePurchased = purchasedReleases.includes(
                  release.id
                );
                if (
                  ctx.me.purchasedTracks &&
                  (purchasedTracks.includes(id) || isRealeasePurchased)
                ) {
                  return (
                    <DownloadPurchasedTrack
                      showTitle={false}
                      content={<Download />}
                      id={id}
                    />
                  );
                }
                // else {
                //   return (
                //     <button
                //       onClick={e => this.tooglePurchaseModal(true)}
                //       className="posTrack-purchase"
                //     >
                //       $
                //     </button>
                //   );
                // }
              }}
            </UserContext.Consumer>
          )}
        </div>
        {this.state.showPurchase && (
          <PurchaseTrackModal
            tooglePurchaseModal={this.tooglePurchaseModal}
            {...this.props.track}
          />
        )}
      </>
    );
  }
}

export default PosTrack;
