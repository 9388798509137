// import { cummulateSupport } from "../../../utils/utils";

export const trackItem = track => ({
  ...track,
  id: track.release.id,
  tracks: 1,
  cover: track.release.cover
});

export const createStreamedCollection = tracks => {
  const convertedCollection = tracks
    .map(track => ({
      ...track.track,
      updatedAt: track.updatedAt
    }))
    .filter(t => t.release);

  let collection = [];

  convertedCollection.forEach(track => {
    if (!collection.length) {
      collection = [trackItem(track)];
    } else {
      const index = collection.findIndex(
        cItem => cItem.id === track.release.id
      );
      if (index > -1) {
        collection[index] = {
          ...collection[index],
          tracks: collection[index].tracks + 1
        };

        if (new Date(collection[index].updatedAt) < new Date(track.updatedAt)) {
          collection[index] = {
            ...collection[index],
            updatedAt: track.updatedAt
          };
        }
      } else {
        collection = [...collection, trackItem(track)];
      }
    }
  });

  //Filter out broken convertedCollection ()
  collection = collection.filter(track => track.release.artist);
  if (!collection.length) {
    collection = null;
  }

  const sortedCollection = collection
    ? collection.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    : null;

  return sortedCollection;
};

export const createSupportedCollection = data => {
  let cummulatedCollection = [];
  const collection = data.map(supportedArtist => supportedArtist.paymentReceiver ? ({
    artistId: supportedArtist.paymentReceiver.id,
    ammount: supportedArtist.amount,
    picture: supportedArtist.paymentReceiver.picture,
    name: supportedArtist.paymentReceiver.userName
  }) : null).filter(p => p);

  collection.forEach((support, i) => {
    if (!cummulatedCollection.length) {
      cummulatedCollection = [support];
    } else {
      const index = cummulatedCollection.findIndex(
        cItem => cItem.artistId === support.artistId
      );
      if (index > -1) {
        cummulatedCollection[index] = {
          ...cummulatedCollection[index],
          ammount: cummulatedCollection[index].ammount + support.ammount
        };
      } else {
        cummulatedCollection = [...cummulatedCollection, support];
      }
    }
  });

  // console.log(cummulatedCollection);

  return cummulatedCollection;
};

export const createPurchasedCollection = (tracks, releases) => {
  const trackItems = tracks
    .filter(track => track.track !== null)
    .map(track => ({
      id: track.id,
      type: "track",
      createdAt: track.createdAt,
      title: track.track.title,
      artist: track.track.artist,
      cover: track.track.release.cover,
      release: track.track.release
    }));

  const releasesItems = releases.map(release => {
    const { id, title, artist, cover } = release.release;
    return {
      type: "release",
      createdAt: release.createdAt,
      id,
      title,
      artist: artist || "**no artist bug**",
      cover,
      release: release.release
    };
  });

  return [...releasesItems, ...trackItems].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
};
