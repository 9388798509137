import React, { useEffect } from 'react';
import queryString from 'query-string'
import draftToHtml from "draftjs-to-html";



const useMountEffect = (fun) => useEffect(fun, [])


const TnCPage = (props) => {
    useMountEffect(() => {
        const scrollTarget = queryString.parse(props.location.search).section;
        const el = window.document.getElementById(scrollTarget)
        if(scrollTarget && el){
            window.scrollTo(0, el.offsetTop);
        }
    })


    const { termsConditions } = props;
    const terms = draftToHtml(JSON.parse(termsConditions));



    return (
    <div className="rich-txt" dangerouslySetInnerHTML={{ __html: terms }} />
    );
}

export default TnCPage;
