import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ChangePasswordForm = props => {
  const { handleSubmit, error, loading } = props;
  return (
    <Formik
      initialValues={{
        current: "",
        new: "",
        confirm: ""
      }}
      validationSchema={Yup.object().shape({
        current: Yup.string().required("password required"),
        new: Yup.string()
          .notOneOf([Yup.ref("current")], "different password required")
          .required("new password required")
          .min(6, "password must be 6 characters minimum"),
        confirm: Yup.string()
          .oneOf([Yup.ref("new"), null], "passwords must match")
          .required("password confirmation required")
      })}
      onSubmit={values => {
        handleSubmit(values);
      }}
    >
      {({ values, errors }) => {
        return (
          <Form style={loading ? { opacity: 0.2 } : {}} className="loginForm">
            {error ? (
              <p className="text-right" style={{ color: "red" }}>
                {error.graphQLErrors[0].message}
              </p>
            ) : (
              ""
            )}

            <div className="formRow">
              <label className="formLabel" htmlFor="current">
                current
              </label>
              <Field name="current" type="password" />
              <ErrorMessage
                name="current"
                className="formError"
                component="div"
              />
            </div>
            <div className="formRow">
              <label className="formLabel" htmlFor="new">
                new
              </label>
              <Field name="new" type="password" />
              <ErrorMessage name="new" className="formError" component="div" />
            </div>
            <div className="formRow">
              <label className="formLabel" htmlFor="confirm">
                confirm
              </label>
              <Field name="confirm" type="password" />
              <ErrorMessage
                name="confirm"
                className="formError"
                component="div"
              />
            </div>

            <div className="formRow">
              <button style={{paddingLeft: "70%"}} type="submit" className="formBtn">
                save >
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
