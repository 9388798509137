import {gql} from 'apollo-boost'


export const GET_ALERTS = gql`

query me{
  me{
    id
    alertMessage{
      id
      type
      read
      track{
        id
        title
        publicationDate
        release{
          id
          title
          artist{
            id
            userName
          }
        }
      }
      release{
        id
        title
        type
        publicationDate
      }
      artist{
        id
        userName
      }
    }
  }
}
`;
