import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InfoModal from "../../shared/InfoModal";
import ResetPassword from "./PasswordReset";

const UserLoginForm = (props) => {
  const [showForgot, toggleForgot] = useState(false);

  const { handleSubmit, error: Qerror, loading } = props;

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          rememberMe: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required("required"),
          password: Yup.string().required("password required"),
        })}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        render={({ values, error }) => {
          return (
            <Form
              style={loading ? { opacity: 0.2 } : {}}
              className="loginForm_default"
            >
              {Qerror ? (
                <p className="text-right" style={{ color: "red" }}>
                  invalid email or password
                </p>
              ) : (
                ""
              )}

              <div className="formRow">
                <label className="formLabel" htmlFor="email">
                  email
                </label>
                <Field name="email" type="email" />
                <ErrorMessage
                  name="email"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="password">
                  pw
                </label>
                <button
                  type="button"
                  onClick={(e) => toggleForgot(true)}
                  style={{ position: "absolute", right: "30px" }}
                  tabIndex="-1"
                >
                  forgot?
                </button>
                <Field name="password" type="password" />
                <ErrorMessage
                  name="password"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <div className="formCol mgt10">
                  <Field type="checkbox" id="remember" name="rememberMe" />
                  <label className="checkbox-label" htmlFor="remember">
                    remember me
                  </label>
                </div>
                <div className="formCol">
                  <button type="submit" className="formBtn">
                    log in >
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      />

      {showForgot && (
        <InfoModal onHide={(e) => toggleForgot(false)}>
          <ResetPassword onHide={(e) => toggleForgot(false)} />
        </InfoModal>
      )}
    </>
  );
};

export default UserLoginForm;
