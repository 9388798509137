import React, { Component } from "react";
import queryString from "query-string";
import PosTop from "./PosTop";
import PosTracksList from "./PosTracksList";
import PosBottom from "./PosBottom";
import PosFooter from "./PosFooter";
import PlayerContext from "../../context/PlayerContext";
import AlertDialog from "../alerts/AlertDialog";
import { getTrackIndex } from "../../utils/utils";

class PosPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlerts: false
    };
  }

  static contextType = PlayerContext;
  shouldStartPlay = queryString.parse(this.props.location.search).start;

  componentDidMount = () => {
    const {
      context: { setPlaylistAndPlay, togglePlayer },
      shouldStartPlay
    } = this;
    const playlist = this.props.data.getRelease.tracks;
    const trackId = queryString.parse(this.props.location.search).track;

    if (shouldStartPlay) {
      setPlaylistAndPlay(playlist, getTrackIndex(playlist, trackId));
      togglePlayer(true);
    }
  };

  showAlert = () => {
    this.setState({ showAlerts: true });
  };

  hideAlert = () => {
    this.setState({ showAlerts: false });
  };

  render() {
    const { getRelease } = this.props.data;

    return (
      <div className="pos-wrapper">
        <div className="pos-container">
          <PosTop
            isAllowed={getRelease.isAllowed}
            showAlert={this.showAlert}
            release={getRelease}
          />
          <PosTracksList
            isAllowed={getRelease.isAllowed}
            trackId={this.trackId}
            release={getRelease}
            tracks={getRelease.tracks}
          />
          <PosBottom {...getRelease} />
          <PosFooter release={getRelease} />
        </div>

        {this.state.showAlerts && <AlertDialog onHide={this.hideAlert} />}
      </div>
    );
  }
}

export default PosPage;
