import React, { Component } from "react";
import { Link } from "react-router-dom";
import { releasePath, artistPath } from "../../utils/utils";

class SearchDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "artist"
    };
  }

  setTab = name => this.setState({ activeTab: name });

  render() {
    const { artists, releases } = this.props.results;
    const { activeTab } = this.state;
    const tracks = this.props.tracks.filter(track => track.files !== null);

    const artistsRes = artists.sort(function(a, b) {
      if (a.userName < b.userName) {
        return -1;
      }
      if (a.userName > b.userName) {
        return 1;
      }
      return 0;
    });

    const releasesRes = releases.sort(function(a, b) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });

    const tracksRes = tracks.sort(function(a, b) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });

    return (
      <div className="searchDetails">
        <div className="searchDetails-nav">
          <button
            onClick={() => this.setTab("artist")}
            className={`labelList-btn searchDetails-btn ${
              this.state.activeTab === "artist" ? "active" : ""
            }`}
          >
            artists{" "}
            <span className="searchdetails-num">({artists.length})</span>
          </button>

          <button
            onClick={() => this.setTab("release")}
            className={`labelList-btn searchDetails-btn ${
              this.state.activeTab === "release" ? "active" : ""
            }`}
          >
            releases{" "}
            <span className="searchdetails-num">({releases.length})</span>
          </button>

          <button
            onClick={() => this.setTab("track")}
            className={`labelList-btn searchDetails-btn ${
              this.state.activeTab === "track" ? "active" : ""
            }`}
          >
            tracks <span className="searchdetails-num">({tracks.length})</span>
          </button>
        </div>

        <div className="searchDetails-content">
          {activeTab === "artist" && (
            <div className="searchDetails-tab">
              {artistsRes.map(a => (
                <Link key={a.id} to={artistPath(a)}>
                  <div className="searchDetails-item">
                    <div
                      style={{ backgroundImage: `url("${a.picture}")` }}
                      className="searchDetails-image"
                    />
                    <p style={{ margin: "8px 0 5px" }}>{a.userName}</p>
                    <p style={{ fontSize: "12px", margin: 0 }}>
                      {a.city} | {a.country}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          )}

          {activeTab === "release" && (
            <div className="searchDetails-tab">
              {releasesRes.map(a => (
                <Link key={a.id} to={releasePath(a)}>
                  <div className="searchDetails-item">
                    <div
                      style={{ backgroundImage: `url("${a.cover}")` }}
                      className="searchDetails-image"
                    />
                    <div>
                      <p style={{ margin: "8px 0 5px" }}>{a.title}</p>
                      <p style={{ fontSize: "12px", margin: 0 }}>
                        {a.artist.userName}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}

          {activeTab === "track" && (
            <div className="searchDetails-tab">
              {tracksRes.map(a => (
                <Link
                  key={a.id}
                  to={`${releasePath(a.release)}?track=${a.id}}&start=true`}
                >
                  <div className="searchDetails-item">
                    <div
                      style={{ backgroundImage: `url("${a.cover}")` }}
                      className="searchDetails-image"
                    />
                    <div>
                      <p style={{ margin: "8px 0 5px" }}>{a.title}</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SearchDetails;
