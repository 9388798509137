import React, { Component } from "react";
import { Link } from "react-router-dom";
import { IcoDots } from "../shared/icons";
import { trackPathAlt, releasePathAlt, artistPath } from "../../utils/utils";
import AlertItemControls from "./AlertItemControls";
import moment from "moment";

class AlertItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false
    };
  }

  openDialog = () => this.setState({ showDialog: true });
  closeDialog = () => this.setState({ showDialog: false });

  render() {
    const { id, type, release, track, artist, read } = this.props;

    const isTrackAlert = type === "Track";
    const displayDate = date =>
      moment(date)
        .utc()
        .format("MM.DD.YYYY");

    return (
      <div className={`alertItem ${read && "read"}`}>
        {this.state.showDialog && (
          <AlertItemControls alertId={id} closeDialog={this.closeDialog} />
        )}

        <div className="alertItem-top">
          <div className="alertItem-lines" />
          <p className="alertItem-type">
            {isTrackAlert ? "new track" : "new release"}
          </p>
          <button onClick={this.openDialog} className="alertItem-btn">
            <IcoDots />
          </button>
        </div>
        {isTrackAlert ? (
          <div className="alertItem-body">
            <p>
              <Link to={artistPath(artist)}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontFamily: "AmericanTypewriter-Bold",
                    textTransform: "none"
                  }}
                >
                  {artist.userName}
                </span>
              </Link>
              <span> | </span>
              <Link
                style={{ fontWeight: "600" }}
                to={trackPathAlt(track, release, artist)}
              >
                {track.title}
              </Link>
              <span> | </span>
              <Link to={releasePathAlt(release, artist)}>{release.title} </Link>
              <span> </span>
              <span style={{ color: "#ccc" }} className="alertItem-album">
                {release.type}
              </span>
              <span> | </span>
              {displayDate(track.publicationDate)}
            </p>
          </div>
        ) : (
          <div className="alertItem-body">
            <p>
              <Link style={{ fontWeight: "bold" }} to={artistPath(artist)}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontFamily: "AmericanTypewriter-Bold",
                    textTransform: "none"
                  }}
                >
                  {artist.userName}
                </span>
              </Link>
              <span> | </span>
              <Link
                style={{ fontWeight: "600" }}
                to={releasePathAlt(release, artist)}
              >
                {release.title} {"  "}
              </Link>
              <span style={{ color: "#ccc" }}>{release.type}</span>
              <span> | </span>
              {displayDate(release.publicationDate)}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default AlertItem;
