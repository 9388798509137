import React, { Component } from 'react';
import PlayerContext from '../../context/PlayerContext';

import { IcoPlay, IcoPause, IcoRewind, IcoForward } from '../shared/icons';

class FxFPlayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			releases: this.props.releases,
			duration: 0,
			currentTime: 0
		};

		this.timeline = React.createRef();
		this.handle = React.createRef();
	}

	static contextType = PlayerContext;

	getTrackIndex = (playlist, trackId) => playlist.map((t) => t.id).indexOf(trackId);

	setDuration = () => this.setState({ duration: this.context.track.duration });

	trackProgress = () => this.setState({ currentTime: this.context.track.currentTime });

	updatePosition = () => {
		let ratio = this.context.track.currentTime / this.context.track.duration;
		let position = this.timeline.current.offsetWidth * ratio;
		this.positionHandle(position);
	};

	componentDidMount() {
		if (this.context.track) {
			this.context.track.addEventListener('loadedmetadata', this.setDuration, true);
			this.context.track.addEventListener('timeupdate', this.trackProgress, true);
			this.onUpdatePos();
		}
	}

	componentWillUnmount() {
		if (this.context.track) {
			this.context.track.removeEventListener('timeupdate', this.updatePosition, true);
			this.context.track.removeEventListener('loadedmetadata', this.setDuration, true);
			this.context.track.removeEventListener('timeupdate', this.trackProgress, true);
			this.context.togglePlayer(true);
		}
	}

	onUpdatePos = () => {
		this.context.track.addEventListener('timeupdate', this.updatePosition, true);
	};

	positionHandle = (position) => {
		let timelineWidth = this.timeline.current.offsetWidth - this.handle.current.offsetWidth;
		let handleLeft = position - this.timeline.current.offsetLeft;
		if (handleLeft >= 0 && handleLeft <= timelineWidth) {
			this.handle.current.style.marginLeft = handleLeft + 'px';
		}
		if (handleLeft < 0) {
			this.handle.current.style.marginLeft = '0px';
		}
		if (handleLeft > timelineWidth) {
			this.handle.current.style.marginLeft = timelineWidth + 'px';
		}
	};

	mouseMove = (e) => {
		const duration = this.context.track.duration || 0;
		this.positionHandle(e.pageX);
		this.context.track.currentTime = e.pageX / this.timeline.current.offsetWidth * duration;
	};

	mouseUp = (e) => {
		window.removeEventListener('mousemove', this.mouseMove);
		window.removeEventListener('mouseup', this.mouseUp);
	};

	mouseDown = (e) => {
		window.addEventListener('mousemove', this.mouseMove);
		window.addEventListener('mouseup', this.mouseUp);
	};

	render() {
		const {
			currentTrackIndex,
			playlist,
			playNextTrack,
			playPrevTrack,
			playbackPaused,
			pause,
			resume
		} = this.context;
		const curentTrack = playlist ? playlist[currentTrackIndex] : null;
    console.log('playlist', playlist);

		return (
			<div className="fxfPlayer fxfPage">
				<div className="fxfPlayer-timeline" onClick={this.mouseMove} ref={this.timeline}>
					<div className="fxfPlayer-handle" onMouseDown={this.mouseDown} ref={this.handle} />
				</div>

				<div className="fxfPlayer-info" onClick={(e) => this.context.togglePlayerExpandedPlayer(true)}>
					{curentTrack && (
						<React.Fragment>
							<p className="fxfTrack-title">
								<span>{currentTrackIndex + 1}</span> {curentTrack.title}
							</p>
							<p className="fxfTrack-release">
								{curentTrack.release.artist.userName}
								<span>{curentTrack.type}</span>
							</p>
						</React.Fragment>
					)}
				</div>
				<div className="fxfPlayer-controls">
					<button
						type="button"
						onClick={(e) => {
							this.positionHandle(0);
							playPrevTrack();
						}}
					>
						<IcoRewind className={'fxfPlayer-ico'} />
					</button>

					{playbackPaused ? (
						<button type="button" onClick={resume}>
							<IcoPlay className={'fxfPlayer-ico'} />
						</button>
					) : (
						<button type="button" onClick={pause}>
							<IcoPause className={'fxfPlayer-ico'} />
						</button>
					)}

					<button
						type="button"
						onClick={(e) => {
							this.positionHandle(0);
							playNextTrack(false);
						}}
					>
						<IcoForward className={'fxfPlayer-ico'} />
					</button>
				</div>
			</div>
		);
	}
}

export default FxFPlayer;
