import React from "react";
import { Link } from "react-router-dom";
import { artistPath } from "../../../utils/utils";

const ArchiveItemArtist = ({ id, userName, picture }) => (
  <div className="sliderItem">
    <Link to={artistPath({ id, userName })} style={{ cursor: "poiner" }}>
      <div
        style={{
          backgroundImage: `url("${picture}")`,
          backgroundColor: "#ccc"
        }}
        className="sliderItem-cover"
      >
        <img style={{ width: "100px", height: "100px" }} src={picture} alt="" />
      </div>
      <div>
        <p className="sliderItem-artist">{userName}</p>
      </div>
    </Link>
  </div>
);

export default ArchiveItemArtist;
