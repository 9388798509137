import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InfoModal from "../shared/InfoModal";
import Temp from "../../utils/Temp";

const UserDetailsForm = props => {
  const [zipModal, showZipModal] = useState(false);
  const [supportModal, showSupportModal] = useState(false);

  const {
    userName,
    email,
    zip,
    firstName,
    supportTier,
    /* id, */
    frontendId
  } = props.user;
  const { loading, error } = props;

  return (
    <div>
      <Formik
        initialValues={{
          userName: userName ? userName : "",
          firstName: firstName ? firstName : "",
          email: email ? email : "",
          zip: zip ? zip : "",
          supportTier: supportTier ? supportTier : 0
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("ivalid email address")
            .required("email name is required"),
          userName: Yup.string().required("user name required"),
          supportTier: Yup.number().min(1, "1 is minimal value")
        })}
        onSubmit={(values, actions) => {
          props.onSubmit(values);
          actions.setStatus({
            message: "changes saved"
          });
        }}
      >
        {({ status, resetForm, isSubmitting }) => (
          <Form
            style={loading ? { opacity: 0.2 } : {}}
            className="userDetails-form"
          >
            {error ? (
              <p className="text-right" style={{ color: "red" }}>
                {error.graphQLErrors[0].message}
              </p>
            ) : (
              ""
            )}
            <div className="userDetails-top">
              <div className="formRow">
                <label className="formLabel" htmlFor="firstName">
                  name
                </label>
                <Field name="firstName" type="text" />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="userName">
                  user
                </label>
                <Field name="userName" type="text" />
                <ErrorMessage
                  name="userName"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="email">
                  email
                </label>
                <Field name="email" type="email" />
                <ErrorMessage
                  name="email"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="zip">
                  zip{" "}
                  <button
                    type="button"
                    onClick={e => showZipModal(true)}
                    tabIndex="-1"
                  >
                    [?]
                  </button>
                </label>
                <Field name="zip" type="text" />
                <span
                  style={{ fontSize: "10px", width: "100%", marginTop: "10px" }}
                />
                <div className="formRow">
                  <div className="col">
                    <Link to="/you/change-password">change pw</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="user-support">
              <div style={{ marginBottom: 0 }} className="formRow">
                <label className="formLabel" htmlFor="supportTier">
                  support tier{" "}
                  <button onClick={e => showSupportModal(true)} tabIndex="-1">
                    [?]
                  </button>
                </label>
                <div style={{ width: "40%" }} className="supportField">
                  <Field
                    style={{ width: "100%" }}
                    name="supportTier"
                    type="number"
                  />
                </div>
                <ErrorMessage
                  name="supportTier"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="firstName">
                  support total to date
                </label>
                <p>{`USD $${props.user.supportTotal || 0}`}</p>
              </div>
              <div className="formRow">
                <div className="col" />
                <div className="col">
                  <button
                    type="submit"
                    className="formBtn float-right"
                    disabled={isSubmitting}
                  >
                    {'save >'}
                  </button>
                </div>
              </div>
              <div className="formRow">
                <div className="col">
                  <p className="userId">No.{frontendId}</p>
                </div>
              </div>
            </div>

            {status && status.message && (
              <Temp onHide={() => resetForm()}>
                <div className="formRow">
                  <div className="col" />
                  <div className="col">
                    <div className="formSuccess">{status.message}</div>
                  </div>
                </div>
              </Temp>
            )}
          </Form>
        )}
      </Formik>

      {zipModal && (
        <InfoModal onHide={e => showZipModal(false)} header={<p>zip code</p>}>
          <p>
            zip code is requested in order to present future location-based
            features.
          </p>
        </InfoModal>
      )}

      {supportModal && (
        <InfoModal
          onHide={e => showSupportModal(false)}
          header={<p>support tier</p>}
        >
          <p>
            designate a preset value for contributions of artist “support,"
            which are stand-alone acts of artist compensation with no future
            commitment (and no exchange of goods). when you click to support an
            artist, this value will appear as a default for checkout.
            <br />
            <br />a preset support tier allows support transactions to be more
            efficient, but you may still increase or decrease the value manually
            at checkout, and you can change the preset tier value here at any
            time.
          </p>
        </InfoModal>
      )}
    </div>
  );
};

export default UserDetailsForm;
