import React from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import ChangePasswordForm from "./CahngePasswordForm";

const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      id
    }
  }
`;

class ChangePasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSucces: false
    };
  }

  render() {
    return (
      <div className="userPage-wrapper">
        <div className="page-container">
          <p style={{ textAlign: "right", paddingRight: "10px" }}>change pw</p>

          <Mutation
            mutation={CHANGE_PASSWORD}
            onCompleted={data => {
              this.setState({ showSucces: true });
              setTimeout(() => {
                this.props.history.replace("/you");
              }, 1000);
            }}
          >
            {(changePassword, { loading, error }) => (
              <>
                <ChangePasswordForm
                  loading={loading}
                  error={error}
                  handleSubmit={values => {
                    changePassword({
                      variables: {
                        oldPassword: values.current,
                        newPassword: values.new
                      }
                    });
                  }}
                />
                {this.state.showSucces && (
                  <p style={{ color: "green" }}>password updated</p>
                )}
              </>
            )}
          </Mutation>
        </div>
      </div>
    );
  }
}

export default ChangePasswordContainer;
