import { gql } from "apollo-boost";

export default gql`
  query appSearch($searchText: String) {
    appSearch(searchText: $searchText) {
      artists {
        id
        userName
        firstName
        lastName
        picture
        country
        state
        city
      }
      releases {
        id
        title
        cover
        artist {
          id
          userName
          firstName
          lastName
        }
      }
      preReleases {
        id
        title
        cover
        artist {
          id
          userName
          firstName
          lastName
        }
      }
      tracks {
        id
        title
        release {
          id
          cover
          title
          artist {
            id
            userName
            firstName
            lastName
          }
        }
      }
    }
  }
`;
