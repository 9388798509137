import React, { useEffect } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_LYRICS = gql`
  query getTrack($trackId: ID!) {
    getTrack(trackId: $trackId) {
      id
      title
      lyrics
    }
  }
`;

const TrackLyrics = props => {
  const trackId = props.match.params.trackId;

  useEffect(() => {
    document.documentElement.style.setProperty("--bg", `#000`);
    return () => document.documentElement.style.setProperty("--bg", `#fff`);
  }, []);

  return (
    <div className="releaseCredits-container pos-wrapper">
      <div className="releaseCredits-content">
        <Query query={GET_LYRICS} variables={{ trackId: trackId }}>
          {({ loading, error, data }) => {
            if (loading) return "Loading...";
            if (error) return `Error! ${error.message}`;
            return (
              <>
                <h2 style={{ color: "#fff" }}>{data.getTrack.title}</h2>
                <p style={{ color: "#fff", whiteSpace: "pre-line" }}>
                  {data.getTrack.lyrics}
                </p>
              </>
            );
          }}
        </Query>
      </div>
    </div>
  );
};

export default TrackLyrics;
