import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Query } from "react-apollo";

import SearchDContainer from "../searchDesktop/SearchDContainer";
import gql from "graphql-tag";
// import { LoaderMain } from "../shared/loaders";

import { IcoSearch, IcoPrev } from "../shared/icons";

import Logo from "../../assets/images/logo-white.png";
import LogoDark from "../../assets/images/logo-black.png";

const GET_HOME = gql`
  query {
    homePageByWeek(weekStart: "2019-04-15") {
      id
      credits
      labelColor
      picture
      weekStart
      weekEnd
      createdAt
      updatedAt
    }
  }
`;

class TopbarWithSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false
    };
  }

  close = ({ history, location }) => {
    history.replace(location.pathname);
    this.setState({ showSearch: false });
  };

  open = () => this.setState({ showSearch: true });

  render() {
    const isHome = this.props.location.pathname === "/";
    const isCredits = this.props.location.pathname.includes("/credits");
    const { showSearch } = this.state;
    return (
      <Query query={GET_HOME}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error! ${error.message}`;
          const { labelColor } = data.homePageByWeek;

          const isBlack = labelColor === "White_on_Black";

          return (
            <div
              style={{
                backgroundColor: isCredits ? "#000" : "",
                background: isHome ? "none" : isCredits ? "#000" : "#fff"
              }}
              className={`topBar-container ${
                (isHome && isBlack) || isCredits ? "dark" : ""
              }`}
            >
              <div className="topBarInner">
                <div className="topBar-left">
                  {!isHome && (
                    <button
                      onClick={this.props.history.goBack}
                      className={`topBar-back ${isCredits ? "white" : ""}`}
                    >
                      <IcoPrev />
                    </button>
                  )}
                </div>
                <div className="topBar-right">
                  {showSearch && (
                    <SearchDContainer
                      {...this.props}
                      onClose={e => this.close(this.props)}
                    />
                  )}
                  <button onClick={this.open}>
                    <IcoSearch
                      className={`topBar-searchIco ${
                        (isHome && !isBlack) || isCredits ? "" : "dark"
                      }`}
                    />
                  </button>
                  <Link to="/">
                    <img
                      className="topBar-logoIco"
                      src={(isHome && !isBlack) || isCredits ? Logo : LogoDark}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(TopbarWithSearch);
