import React from "react";
import { Query } from "react-apollo";

import AlertItem from "./AlertItem";
import { LoaderMain } from "../shared/loaders";
import { GET_ALERTS } from "./getAlertsMsgQuery";

const AlertsContainer = () => {
  return (
    <div className="alerts-wrapper">
      <Query query={GET_ALERTS}>
        {({ loading, error, data }) => {
          if (loading) return <LoaderMain />;
          if (error) return `Error! ${error.message}`;
          const alerts = data.me.alertMessage
            .filter(alert => alert.release !== null)
            .filter(alert => alert.artist !== null);

          return (
            <div className="alerts-list">
              {!alerts.length && (
                <p>
                  artist alerts will appear here when selected (on artist or
                  release pages)
                </p>
              )}
              {alerts.map((alert, i) => {
                if (alert.type === "Release") {
                  return (
                    <AlertItem key={alert.id} type={"Release"} {...alert} />
                  );
                } else {
                  return <AlertItem key={alert.id} type={"Track"} {...alert} />;
                }
              })}
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default AlertsContainer;
