import React, { Component } from 'react';
import PlayerContext from '../../context/PlayerContext';
import playIco from '../../assets/images/ui/play.png';
import pauseIco from '../../assets/images/ui/pause.png';
import prevIco from '../../assets/images/ui/prev.png';
import nextIco from '../../assets/images/ui/next.png';
import { Link } from 'react-router-dom';
import { releasePath, artistPath } from '../../utils/utils';
import { totalTimeFormat, durationTime } from '../../utils/timeFormat';
import { IcoX } from '../shared/icons';
import TrackIndex from '../playback/TrackIndex';
import onResize from '../../utils/resize';

class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: 0,
      currentTime: 0,
      showShare: false,
      trt: '00:00:00'
    };

    this.timeline = React.createRef();
    this.handle = React.createRef();
  }

  static contextType = PlayerContext;

  hideShare = () => this.setState({ showShare: false });

  setDuration = () => {
    this.setState({ duration: this.context.track.duration });

    const time = this.context
      ? totalTimeFormat(
          this.context.playlist.reduce((prevVal, currentVal) => {
            return parseFloat(prevVal.duration) + parseFloat(currentVal.duration);
          }, 0)
        )
      : '00:00:00';

    this.setState({ trt: time });
  };
  trackProgress = () => this.setState({ currentTime: this.context.track.currentTime });

  updatePosition = () => {
    const durration = this.context.track.duration;
    let ratio = this.context.track.currentTime / durration;
    let position = this.timeline.current.offsetWidth * ratio;
    this.positionHandle(position + this.timeline.current.offsetLeft);
  };

  componentDidMount() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    onResize(() => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    this.setDuration();
    this.context.track.addEventListener('loadedmetadata', this.setDuration, true);
    this.context.track.addEventListener('timeupdate', this.trackProgress, true);
    this.onUpdatePos();
  }

  componentWillUnmount() {
    this.context.track.removeEventListener('timeupdate', this.updatePosition, true);
    this.context.track.removeEventListener('loadedmetadata', this.setDuration, true);
    this.context.track.removeEventListener('timeupdate', this.trackProgress, true);
    this.context.togglePlayer(true);
  }

  onUpdatePos = () => {
    this.context.track.addEventListener('timeupdate', this.updatePosition, true);
  };

  positionHandle = (position) => {
    let timelineWidth = this.timeline.current.offsetWidth - this.handle.current.offsetWidth;
    let handleLeft = position - this.timeline.current.offsetLeft;

    if (handleLeft >= 0 && handleLeft <= timelineWidth) {
      this.handle.current.style.marginLeft = handleLeft + 'px';
    }
    if (handleLeft < 0) {
      this.handle.current.style.marginLeft = '0px';
    }
    if (handleLeft > timelineWidth) {
      this.handle.current.style.marginLeft = timelineWidth + 'px';
    }
  };

  mouseMove = (e) => {
    const duration = this.context.track.duration || 0;
    this.positionHandle(e.pageX - this.timeline.current.offsetLeft);
    this.context.track.currentTime =
      (e.pageX - this.timeline.current.offsetLeft) / this.timeline.current.offsetWidth * duration;
  };

  mouseUp = (e) => {
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('mouseup', this.mouseUp);
  };

  mouseDown = (e) => {
    window.addEventListener('mousemove', this.mouseMove);
    window.addEventListener('mouseup', this.mouseUp);
  };

  render() {
    const {
      currentTrackIndex,
      playlist,
      playNextTrack,
      playPrevTrack,
      playbackPaused,
      pause,
      resume
    } = this.context;
    const curentTrack = playlist[currentTrackIndex];

    return (
      <div id="appPlayer" className="playback-full__container expanded">
        <div className="playback-full__cover">
          <img src={curentTrack && curentTrack.release.cover} alt="" />
        </div>

        <div className="player-side">
          {curentTrack && (
            <div className="playback-middle">
              <div className="playback-full__track-info">
                <h3 className="track-info-top">
                  <TrackIndex trackIndex={this.context.currentTrackIndex + 1} />
                  {curentTrack.title}
                </h3>
                <h4 className="track-info-bottom">
                  <Link
                    onClick={(e) => this.context.togglePlayerExpandedPlayer(false)}
                    to={artistPath(curentTrack.release.artist)}
                  >
                    {curentTrack.release.artist.userName} |
                  </Link>
                  <Link
                    onClick={(e) => this.context.togglePlayerExpandedPlayer(false)}
                    to={releasePath(curentTrack.release)}
                  >
                    {curentTrack.release.title}
                  </Link>
                  <span className="ep"> {curentTrack.release.type.toLowerCase()}</span>
                </h4>
              </div>
            </div>
          )}

          <div className="player-controler">
            <div className="pc-top">
              <div className="pc-album" />

              <div className="pc-controls">
                <button
                  onClick={(e) => {
                    this.positionHandle(0);
                    playPrevTrack();
                  }}
                  className="controls-btn controls-prev"
                >
                  <img className="controls-ico" src={prevIco} alt="" />
                </button>
                <button
                  onClick={(e) => playbackPaused ? resume() : pause()}
                  className="controls-btn controls-play"
                >
                  <img
                    className="controls-ico ico-lg"
                    src={playbackPaused ? playIco : pauseIco}
                    alt=""
                  />
                </button>

                <button
                  onClick={(e) => {
                    this.positionHandle(0);
                    playNextTrack();
                  }}
                  className="controls-btn controls-next"
                >
                  <img className="controls-ico" src={nextIco} alt="" />
                </button>
              </div>

              <div className="pc-outbox" />
            </div>

            <div className="pc-bottom playback">
              <div className="fxfPlayer-timeline" onClick={this.mouseMove} ref={this.timeline}>
                <div
                  className="fxfPlayer-handle"
                  style={{ height: '20px', marginTop: '-20px' }}
                  onMouseDown={this.mouseDown}
                  ref={this.handle}
                />
              </div>
              <div className="pc-progress-indicators">
                <div className="progress-nums progress-left">
                  <p>{durationTime(this.state.currentTime, false)}</p>
                </div>
                <div className="progress-nums progress-right">
                  <p>{durationTime(this.state.currentTime - this.state.duration, true)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="close-playback" onClick={(e) => this.context.togglePlayerExpandedPlayer(false)}>
          <IcoX />
        </button>
      </div>
    );
  }
}

export default Player;
