import React from "react";
import { IcoSearch, IcoPrev } from "../shared/icons";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/logo-black.png";

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="topBar-container">
        <div className="topBarInner">
          <div className="topBar-left" >
          <button onClick={this.props.history.goBack} className="topBar-back">
            <IcoPrev/>
          </button>
          </div>
          <div className="topBar-right">
            <button>
              <IcoSearch className="topBar-searchIco" />
            </button>
            <Link to="/">
              <img className="topBar-logoIco" src={Logo} alt="" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
