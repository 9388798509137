import React, { Component } from "react";
import { NavLink, Route } from "react-router-dom";
import RegisterContainer from "./register/RegisterContainer";
import UserLoginContainer from "./login/UserLoginContainer";
import logo from "../../assets/images/logo-black.png";

const HomeTop = () => {
  return (
    <>
      <div className="login-logo__wrapper">
        <img src={logo} alt="The van Logo" className="login-logo" />
      </div>
      <div className="log-reg__bar">
        <NavLink
          exact
          className="btn login-btn"
          activeClassName="active"
          to="/login"
        >
          log in
        </NavLink>

        <NavLink
          exact
          className="btn login-btn"
          activeClassName="active"
          to="/login/register"
          style={{ marginLeft: "50px" }}
        >
          register
        </NavLink>
      </div>
    </>
  );
};
class UserContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSlogin: true,
    };
  }
  render() {
    console.log(this.props);
    return (
      <div className="login-wrapper">
        <div className="loggin-container">
          <HomeTop />
          <Route exact path="/login" component={UserLoginContainer} />
          <Route exact path="/login/register" component={RegisterContainer} />
        </div>
      </div>
    );
  }
}

export default UserContainer;

// import React from "react";
// import { Route, Link, NavLink } from "react-router-dom";
// import RegisterContainer from "./register/RegisterContainer";
// import UserLoginContainer from "./login/UserLoginContainer";
// import TopbarWithSearch from "../shared/TopBarWithSearch";

// class UserContainer extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       showSlogin: true,
//     };
//   }
//   render() {
//     return (
//       <>
//         <TopbarWithSearch theme="dark" />
//         <div className="user-wrapper">
//           <div className="user-container">
//             {this.state.showLogin && (
//               <>
//                 <div style={{ textAlign: "center" }}>
//                   <p>
//                     <span style={{ textAlign: "center" }}>
//                       don't have an account?
//                     </span>
//                   </p>
//                   <button
//                     onClick={(e) => this.setState({ showLogin: false })}
//                     style={{ fontWeight: "bold" }}
//                     className="btn-black"
//                   >
//                     {" "}
//                     register{" "}
//                   </button>
//                 </div>
//                 <UserLoginContainer {...this.props} />{" "}
//               </>
//             )}
//             {!this.state.showLogin && (
//               <>
//                 <div style={{ textAlign: "center" }}>
//                   <p>
//                     <span style={{ textAlign: "center" }}>
//                       already have an account?
//                     </span>
//                   </p>
//                   <button
//                     onClick={(e) => this.setState({ showLogin: true })}
//                     style={{ fontWeight: "bold" }}
//                     className="btn-black"
//                   >
//                     {" "}
//                     log in{" "}
//                   </button>
//                 </div>
//                 <RegisterContainer {...this.props} />
//               </>
//             )}
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default UserContainer;
