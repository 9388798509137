import React from "react";

import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";

const LOGTRACK = gql`
  mutation userAddPlayedTrack($trackId: ID!) {
    userAddPlayedTrack(trackId: $trackId)
  }
`;

class LogTrack extends React.Component {
  componentDidMount() {
    this.props.userAddPlayedTrack();
  }

  render() {
    return null;
  }
}

const LogTrackContainer = ({ trackId, onUnmount }) => {
  return (
    <Mutation
      mutation={LOGTRACK}
      variables={{ trackId }}
      onCompleted={data => {
        onUnmount();
      }}
    >
      {(userAddPlayedTrack, { data, error }) => {
        return (
          <LogTrack
            userAddPlayedTrack={userAddPlayedTrack}
            render={props => null}
          />
        );
      }}
    </Mutation>
  );
};

export default LogTrackContainer;
