import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { artistPath } from '../../utils/utils';

const SuportedSliderItem = (props) => (
  <div className="sliderItem">
    <Link
      to={props.artistId ? artistPath({ id: props.artistId, userName: props.name }) : '/you/archive'}
      style={{ cursor: props.artistId ? 'pointer' : 'auto' }}
    >
      <div style={{ backgroundImage: `url("${props.picture}")` }} className="sliderItem-cover">
        <img style={{ width: '100px', height: '100px' }} src={props.cover} alt="" />
      </div>
      <p className="sliderItem-artist">{props.name}</p>
      <p className="sliderItem-type" style={{ color: 'green', fontSize: '1.2rem' }}>${props.ammount}</p>
    </Link>
  </div>
);

export const EmptySliderItem = () => (
  <div style={{ width: '100px', height: '100px', border: '1px dashed #5a5a5acc' }} className="sliderItem empty" />
);

class SupportedSlider extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }
  scrollLeft = (e) => {
    let element = this.scrollRef.current;
    element.scrollLeft = element.scrollLeft - 200;
  };
  scrollRight = (e) => {
    let element = this.scrollRef.current;
    element.scrollLeft = element.scrollLeft + 200;
  };
  render() {
    const collection = this.props.collection;
    return (
      <div className="slider-container" style={{ position: 'relative' }}>
        <div className="scroll">
          <span className="scroll-left" onClick={this.scrollLeft}>
            {' '}
            &lt;
          </span>{' '}
          &nbsp;{' '}
          <span className="scroll-right" onClick={this.scrollRight}>
            &gt;
          </span>
        </div>
        <div className="slider-inner" ref={this.scrollRef}>
          {collection && collection.length ? (
            collection.map((item, i) => {
              return <SuportedSliderItem key={i} {...item} />;
            })
          ) : (
            <EmptySliderItem />
          )}
        </div>
      </div>
    );
  }
}

export default SupportedSlider;
