import React from "react";

import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { AUTH_TOKEN } from "../../../constants";
import UserLoginForm from "./UserLoginForm";
import UserContext from "../../../context/UserContext";

const LOGIN = gql`
  mutation login($email: EmailAddress!, $password: String!) {
    login(email: $email, password: $password) {
      id
      token
    }
  }
`;

class UserLoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = UserContext;

  _confirm = async (data) => {
    const { token } = data.login;
    this._saveUserData(token);
    this.context.login();
    this.props.history.replace(`/`);
  };

  _saveUserData = (token) => {
    const storage = this.state.rememberMe ? localStorage : sessionStorage;
    storage.setItem(AUTH_TOKEN, token);
  };

  render() {
    return (
      <div className="loginForm-container">
        <Mutation
          mutation={LOGIN}
          onCompleted={(data) => {
            this._confirm(data);
          }}
        >
          {(login, { loading, error }) => {
            return (
              <UserLoginForm
                loading={loading}
                error={error}
                handleSubmit={({ email, password, rememberMe }) => {
                  this.setState({ rememberMe });
                  login({ variables: { email, password } });
                }}
              />
            );
          }}
        </Mutation>
      </div>
    );
  }
}

export default UserLoginContainer;
