import React from "react";
import { ARCHIVE_PURCHASED } from "./archiveQueries";
import { Query } from "react-apollo";
import { LoaderMain } from "../../shared/loaders";
// import ArchiveItem from "./ArchiveItem";
import { createPurchasedCollection } from "./createCollections";
import { PurchasedSliderItem } from "../../shared/PurchasedSlider";

const ArchivePurchased = () => {
  return (
    <Query query={ARCHIVE_PURCHASED} fetchPolicy={"network-only"}>
      {({ loading, error, data }) => {
        if (loading) return <LoaderMain />;
        if (error) return `Error! ${error.message}`;
        const streamedCollection = createPurchasedCollection(
          data.me.purchasedTracks,
          data.me.purchasedReleases
        );

        return (
          <div className="userArchive-wrapper">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "50px",
                justifyContent: "center"
              }}
              className="page-container"
            >
              {streamedCollection.map((item, index) => {
                return (
                  <PurchasedSliderItem display="grid" key={index} {...item} />
                );
              })}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default ArchivePurchased;
