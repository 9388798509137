import React, { useEffect } from "react";
import { IcoChevron } from "../shared/icons";
import { durationTime } from "../../utils/timeFormat";
import { Link } from "react-router-dom";
import { releasePath } from "../../utils/utils";

const TrackCredit = props => {
  const { expandedTrackId, setExpandedTrack, id } = props;
  const isTrackExpaned = id === expandedTrackId;

  useEffect(() => {
    document.documentElement.style.setProperty("--bg", `#000`);
    return () => document.documentElement.style.setProperty("--bg", `#fff`);
  }, []);

  const onTrackClick = id => {
    if (id === expandedTrackId) {
      setExpandedTrack(null);
    } else {
      setExpandedTrack(id);
    }
  };

  return (
    <div className="trackCredit-container">
      <div onClick={() => onTrackClick(id)} className="trackCredits-heading">
        <button className="trackCredit-headingBtn">
          {props.index + 1}. {props.title}
        </button>

        <IcoChevron
          className={`credits-chevron ${
            isTrackExpaned ? "expanded" : "collapsed"
          }`}
        />
      </div>
      {isTrackExpaned && (
        <div className="trackCredit-content">
          <p>
            <span className="t-grey">written: </span>
            {props.written}
          </p>
          <p>
            <span className="t-grey">published: </span>
            {props.published}
          </p>
          <br />
          <p>
            <span className="t-grey">additional personnel:</span>
          </p>
          {props.personnels.map(p => (
            <p key={p.id}>
              {p.name}: {p.instrument.toLowerCase()}
            </p>
          ))}

          <p style={{ marginTop: "50px" }}>
            <span style={{ marginTop: "50px" }} className="t-grey">
              produced:{" "}
            </span>
            {props.produced}
          </p>
          <p>
            <span className="t-grey">engineered: </span>
            {props.engineered}
          </p>
          <p>
            <span className="t-grey">mixed: </span>
            {props.mixed}
          </p>
          <p>
            <span className="t-grey">studio: </span>
            {props.studio}
          </p>
          <p>
            <span className="t-grey">mastered: </span>
            {props.mastered}
          </p>
          <br />
          <p>
            <span className="t-grey">additional credits: </span>
            {props.additionalCredits}
          </p>
          <br />
          <p>{props.about}</p>
          <div style={{ display: "block", borderBottom: "1px dotted" }} />
          <p>{props.copyrightNote}</p>

          <Link
            className="rc-artist-link"
            to={`${releasePath(props.release)}/credits/tracks/lyrics/${id}`}
          >
            lyrics >
          </Link>

          <br />
          <p className="t-grey">© all rights reserved</p>
          <p className="t-grey">TRT: {durationTime(props.duration)}</p>
        </div>
      )}
    </div>
  );
};

export default TrackCredit;
