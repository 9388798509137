import React, { useContext, useState } from "react";
import PlayerContext from "../../context/PlayerContext";
import { releasePath } from "../../utils/utils";
// import { trackSrcBase } from "../../constants";

const FxFGrid = ({ releases, toggleshowFxFPlayer, history }) => {
  const [isPlaying, setPlaying] = useState(false);
  const { currentTrackIndex, setPlaylistAndPlay, playlist } = useContext(
    PlayerContext
  );
  // const cached = [];

  // const preload = index => {
  //   if (!cached.includes(index)) {
  //     cached.push(index);
  //     fetch(`${trackSrcBase}${releases[index].tracks[0].videoId}/source`, {
  //       mode: "no-cors"
  //     });
  //   }
  // };

  const onClickRelease = (trackIndex, releaseId) => {
    const { tracks, artist, cover } = releases[trackIndex];

    const playlist = tracks.map(track => ({
      release: { ...releases[trackIndex] },
      ...track,
      artist,
      cover,
      releaseId
    }));

    setPlaylistAndPlay(playlist, 0);
    let handle = window.document.getElementsByClassName("fxfPlayer-handle")[0];
    if (handle) {
      handle.style.marginLeft = 0;
    }
    toggleshowFxFPlayer();
    setPlaying(true);
  };

  const currentTrackId = () => {
    if (playlist.length) {
      return playlist[currentTrackIndex].releaseId;
    }
  };

  return (
    <div className="fxfInner">
      <div className="fxf-grid">
        {releases.map((r, index) => {
          return (
            <div
              onClick={() => {
                if (currentTrackId() === r.id) {
                  history.push(
                    `${releasePath(r)}?track=${
                      playlist[currentTrackIndex].id
                    }&play=true`
                  );
                } else {
                  onClickRelease(index, r.id);
                }
              }}
              key={r.id}
              className="fxf-box"
            >
              <div
                style={{ backgroundImage: `url('${r.cover}')` }}
                className={`fxf-boxInner ${
                  currentTrackId() === r.id ? "active" : isPlaying ? "fade" : ""
                }`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FxFGrid;
