import React from "react";
import { Link } from "react-router-dom";
import { artistPath } from "../../utils/utils";

const ArtistList = props => {
  const { artists } = props;
  return (
    <div className="artistList-wrapper">
      <div className="artistList-container">
        <div style={{ paddingBottom: "70px" }} className="artistList-grid">
          {artists.map(a => {
            const { id, userName, picture } = a;
            return (
              <Link to={artistPath(a)} key={id} className="artistItem">
                <img src={picture} alt="" />
                <p className="artistItem-name">{userName}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ArtistList;
