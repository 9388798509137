import React from 'react';

export default React.createContext({
    showPlayer: false,
    isPlaying: false,
    playlist: [],
    currentTrackNum: null,
    currentTrackId: null,
    setPlaylist: () => {},
    nextTrack: () => {},
    prevTrack: () => {},
});