import React, { useState } from "react";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import TrackCredit from "./TrackCredits";
import PosBottom from "../pos/PosBottom";

const TRACK_CREDITS = gql`
  query getRelease($releaseId: ID!) {
    getRelease(releaseId: $releaseId) {
      id
      title
      publicationDate
      label {
        id
        userName
      }
      artist {
        id
        userName
      }
      tracks {
        id
        title
        publicationDate
        includeCredits
        written
        published
        produced
        engineered
        mixed
        mastered
        studio
        lyrics
        additionalCredits
        copyrightNote
        about
        duration
        personnels {
          id
          name
          instrument
        }
        duration
      }
    }
  }
`;

const TrackCreditsContainer = props => {
  const [expandedTrack, setExpandedTrack] = useState(null);

  const releaseId = props.match.params.releaseId;

  return (
    <div className="releaseCredits-container pos-wrapper">
      <Query
        query={TRACK_CREDITS}
        variables={{ releaseId: parseInt(releaseId) }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { tracks } = data.getRelease;

          return (
            <div className="trackCredits-page">
              <div className="tracksCreditsList" style={{ color: "#fff" }}>
                {tracks.map((track, index) => (
                  <TrackCredit
                    release={data.getRelease}
                    expandedTrackId={expandedTrack}
                    setExpandedTrack={setExpandedTrack}
                    tracks={tracks}
                    {...track}
                    key={track.id}
                    index={index}
                  />
                ))}
              </div>
              <PosBottom
                tracks={tracks}
                isTrackCredits={true}
                {...data.getRelease}
              />
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default TrackCreditsContainer;
