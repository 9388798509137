export function secToMin(time) {
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export function totalTimeFormat(time) {
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;
  
  let hDispaly;
  let mDisplay;
  let sDisplay;
  
  if (hrs === 0) {
    hDispaly = `00`;
  } else {
    hDispaly = `0${hrs}`;
  }
  
  if (mins === 0) {
    mDisplay = `00`;
  } else if (mins < 10) {
    mDisplay = `0${mins}`;
  } else {
    mDisplay = mins;
  }
  
  if (secs === 0) {
    sDisplay = `00`;
  } else if (secs < 10) {
    sDisplay = `0${secs}`;
  } else {
    sDisplay = secs;
  }
  
  return `${hDispaly}:${mDisplay}:${sDisplay}`;
}

export function durationTime(time, isNegative) {
  let duration = time;

  if (isNegative) {
    duration = Math.abs(time);
  }

  let mins = ~~((duration % 3600) / 60);
  let secs = ~~duration % 60;

  let mDisplay;
  let sDisplay;
  let negative = isNegative;

  if (mins === 0) {
    mDisplay = `00`;
  } else if (mins < 10) {
    mDisplay = `0${mins}`;
  } else {
    mDisplay = mins;
  }

  if (secs === 0) {
    sDisplay = `00`;
  } else if (secs < 10) {
    sDisplay = `0${secs}`;
  } else {
    sDisplay = secs;
  }

  return `${negative ? "-" : ""}${mDisplay}:${sDisplay}`;
}
