import { gql } from "apollo-boost";


export default gql`
  query appSearch($searchText: String) {
    appSearch(searchText: $searchText) {
      artists{
        id
        userName
        firstName
        lastName
        picture
        city
        country
      }
      releases{
        id
        title
        artist{
          id
          userName
          firstName
          lastName
        }
      }
      tracks{
        id
        title
        release{
          id
          title
          artist{
            id
            userName
            firstName
            lastName
          }
        }
      }
    }
  }`
