import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function getUrlParameter(name) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const RESET_PASS = gql`
  query resetPasswordForm(
    $email: EmailAddress!
    $newPassword: String!
    $token: String!
  ) {
    resetPasswordForm(email: $email, newPassword: $newPassword, token: $token)
  }
`;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      runQuery: false,
      error: false,
      loading: false,
      message: null
    };
  }

  render() {
    const resetPassStyles = () => ({
      margin: "11% auto 0",
      maxWidth: "600px",
      padding: "20px",
      opacity: this.state.loadingS ? "0.7" : 1
    });

    const { runQuery } = this.state;

    return (
      <div style={resetPassStyles()} className="container">
        <div className="row">
          <div className="col">
            <h3>reset your password</h3>
          </div>
        </div>

        <div className="login-form__container">
          <Formik
            initialValues={{
              email: "",
              newPass: ""
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              newPass: Yup.string()
                .required("Password is required")
                .min(6, "Password is too short - should be 6 chars minimum.")
            })}
            onSubmit={values => {
              this.setState({ runQuery: true });
            }}
            render={({ isSubmitting, handleReset, values, errors }) => (
              <>
                <Form className="loginForm">
                  <div className="formRow">
                    <label className="formLabel" htmlFor="email">
                      email
                    </label>
                    <Field name="email" type="email" />
                    <ErrorMessage
                      name="email"
                      className="formError"
                      component="div"
                    />
                  </div>

                  <div style={{ flexWrap: "nowrap" }} className="formRow">
                    <label className="formLabel" htmlFor="newPass">
                      new pw
                    </label>
                    <Field name="newPass" type="password" />
                    <ErrorMessage
                      name="newPass"
                      className="formError"
                      component="div"
                    />
                  </div>

                  <div style={{ flexWrap: "nowrap" }} className="formRow">
                    <div className="formCol">
                      <button type="submit" className="formBtn">
                        reset >
                      </button>
                    </div>
                  </div>
                </Form>

                {runQuery && (
                  <Query
                    query={RESET_PASS}
                    variables={{
                      email: values.email,
                      newPassword: values.newPass,
                      token: getUrlParameter("token")
                    }}
                    onCompleted={data => {
                      setTimeout(() => {
                        this.props.history.push("/");
                      }, 2000);
                    }}
                  >
                    {({ data, error, loading }) => {
                      if (loading) {
                        return "...";
                      }
                      if (error) {
                        return (
                          <p style={{ color: "red", padding: "0 20px" }}>
                            {" "}
                            invalid or expired reset password token{" "}
                          </p>
                        );
                      }

                      return (
                        <p style={{ color: "green", fontSize: "12px" }}>
                          password updated
                        </p>
                      );
                    }}
                  </Query>
                )}
              </>
            )}
          />
        </div>
      </div>
    );
  }
}

export default ResetPassword;
