import React from "react";
import { Link } from "react-router-dom";
import { IcoX } from "../shared/icons";

const CookiesContent = props => {
  return (
    <div className="cookies-container">
      <div className="cookies-body">
        <span onClick={props.onHide} className="cookies-close">
          <IcoX />
        </span>
        <h5>cookies &amp; privacy</h5>
        <p>this website uses cookies to offer users the best experience</p>
        <div className="cookies-links">
          <Link to="/us/terms">more information</Link>
          <button onClick={props.onAccept} className="btn-black">
            accept
          </button>
        </div>
      </div>
    </div>
  );
};

class CookiesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookies: true
    };
  }

  async componentDidMount() {
    const isCookieAccepted = await localStorage.getItem("vca");
    if (isCookieAccepted) {
      this.setState({ showCookies: false });
    }
  }

  acceptCookies = () => {
    this.setState({ showCookies: false });
    localStorage.setItem("vca", true);
  };

  hideMessage = () => {
    this.setState({ showCookies: false });
  };

  render() {
    if (!this.state.showCookies) return null;
    return (
      <CookiesContent onHide={this.hideMessage} onAccept={this.acceptCookies} />
    );
  }
}

export default CookiesContainer;
