import React, { Component } from "react";
// import queryString from "query-string";

import { Query } from "react-apollo";
import SEARCH from "./searchQuery";
import { LoaderMain } from "../shared/loaders";
import SearchDetails from "./SearchDetails";

class SearchDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        {this.props.searchFor && (
          <Query
            query={SEARCH}
            variables={{ searchText: this.props.searchFor }}
          >
            {({ loading, error, data }) => {
              if (loading) return <LoaderMain />;
              if (error) return `Error! ${error.message}`;
              return (
                <SearchDetails
                  closeSearch={this.props.closeSearch}
                  results={data.appSearch}
                />
              );
            }}
          </Query>
        )}
      </div>
    );
  }
}

export default SearchDetailsContainer;
