import gql from "graphql-tag";

export const ARCHIVE_STREAMED = gql`
  query me {
    me {
      id
      playedTracks {
        id
        updatedAt
        track {
          id
          title
          artist
          publicationDate
          about
          additionalAdminInfo
          release {
            id
            title
            cover
            publicationDate
            type
            isAllowed
            artist {
              id
              userName
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const ARCHIVE_SUPPORTED = gql`
  query me {
    me {
      id
      support {
        orignalPrice
        paymentReceiver {
          id
          userName
          picture
        }
      }
    }
  }
`;

export const ARCHIVE_PURCHASED = gql`
  query me {
    me {
      id
      purchasedTracks {
        id
        createdAt
        track {
          id
          title
          artist
          publicationDate
          about
          additionalAdminInfo
          release {
            id
            title
            cover
            publicationDate
            type
            isAllowed
            artist {
              id
              userName
              firstName
              lastName
            }
          }
        }
      }
      purchasedReleases {
        id
        createdAt
        release {
          id
          title
          cover
          publicationDate
          type
          isAllowed
          artist {
            id
            userName
            firstName
            lastName
          }
        }
      }
    }
  }
`;
