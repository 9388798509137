import React from "react";
import InfoModal from "../shared/InfoModal";

class FirstTimeSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showWelcome: false,
      showAlertModal: false
    };
  }

  render() {
    return (
      <>
        {localStorage.getItem("firstAlert") ? (
          <>{this.props.btn}</>
        ) : (
          <>
            <button
              onClick={e => this.setState({ showWelcome: true })}
              className="pos-btn"
            >
              alerts /?\
            </button>

            {this.state.showWelcome && (
              <InfoModal
                onHide={e => {
                  this.setState({ showWelcome: false });
                  localStorage.setItem("firstAlert", true);
                }}
                header={<p>artist alerts</p>}
              >
                <p>
                  receive a profile notification when this artist drops a new
                  track or new release. artist will not receive any of your
                  personal information.
                </p>
              </InfoModal>
            )}
          </>
        )}
      </>
    );
  }
}

export default FirstTimeSupport;
