import React, { Component } from "react";

import { Query } from "react-apollo";
import { gql } from "apollo-boost";

import { LoaderMain } from "../shared/loaders";
import NewTracksList from "./NewTracksList";

const GET_NEW_TRACKS = gql`
  query getNewTracks($date: DateTime!) {
    getNewTracks(date: $date) {
      id
      tracks {
        id
        title
        artist
        publicationDate
        about
        additionalAdminInfo
        release {
          id
          title
          publicationDate
          type
          isAllowed
          artist {
            id
            userName
            firstName
            lastName
          }
        }
      }
    }
  }
`;

class NewTracksContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        style={{ maxWidth: "667px", margin: "auto" }}
        className="newRel-wrapper"
      >
        <h2 style={{ paddingLeft: "100px" }} className="newRel-heading">
          new tracks
        </h2>
        <div className="newRel-container">
          <Query query={GET_NEW_TRACKS} variables={{ date: "2019-04-15" }}>
            {({ loading, error, data }) => {
              if (loading) return <LoaderMain />;
              if (error) return `Error! ${error.message}`;
              if (!data.getNewTracks) return null;
              return <NewTracksList tracks={data.getNewTracks.tracks} />;
            }}
          </Query>
        </div>
      </div>
    );
  }
}

export default NewTracksContainer;
