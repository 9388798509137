import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import PosPageFirstTimeVisit from "./PosFirstTimeSupport";
import FirstTimeSupport from "./FirstTimeAlert";
import InfoModal from "../shared/InfoModal";
import { artistPath } from "../../utils/utils";
import UserContext from "../../context/UserContext";
import PaypalBtns from "../paypal/PaypalBtns";
import history from "./../../history";

const PosTop = (props) => {
  const { id, cover, type, title, artist, price, label } = props.release;
  const [selectedPrice, setPrice] = React.useState(price);

  const [showPurchaseModal, tooglePurchaseModal] = useState(false);
  const { me } = useContext(UserContext);

  const merchantId = label ? label.id : artist.id;

  function onsubmit() {
    console.log("in submit:::");
    history.push("/login");
  }

  return (
    <div className="posTop">
      <div className="posTop-left">
        <div
          style={{
            backgroundImage: `url('${cover}')`,
            width: "100px",
            height: "100px",
          }}
          className="posTop-cover"
        />
        {/* {me && me.verified && props.isAllowed && (
          <button
            style={{ width: "100%", padding: "15px" }}
            onClick={e => tooglePurchaseModal(true)}
          >
            $
          </button>
        )} */}
      </div>

      <div className="posTop-right">
        <h6 style={{ color: "#999999" }}>{type.toLowerCase()}</h6>
        <h1 className="pos-title">{title}</h1>
        <div className="dotted-spacer" />
        <Link to={artistPath(artist)}>
          <h2 className="pos-artist">{artist.userName}</h2>
        </Link>
        <div className="pos-btns">
          {!me && (
            <>
              <button onClick={onsubmit} className="pos-btn support-btn">
                support $
              </button>

              <button onClick={onsubmit} className="pos-btn">
                alerts /!\
              </button>
            </>
          )}
          
          {me && me.verified && (
            <>
              <PosPageFirstTimeVisit
                merchantId={artist.id}
                artistId={artist.id}
                username={artist.userName}
                supportTier={me.supportTier}
              />
              <FirstTimeSupport
                btn={
                  <button onClick={props.showAlert} className="pos-btn">
                    alerts /!\
                  </button>
                }
              />
            </>
          )}
          {showPurchaseModal && (
            <InfoModal
              header={
                <p
                  style={{
                    fontSize: "20px",
                    width: "100%",
                    textAlign: "right",
                    paddingRight: "20px",
                  }}
                >
                  purchase release
                </p>
              }
              onHide={(e) => tooglePurchaseModal(false)}
            >
              <div style={{ padding: "10px" }} className="purchaseRelease">
                <p style={{ fontSize: "20px" }}>{title}</p>
                <p style={{ fontSize: "20px", color: "#ccc" }}>
                  {artist.userName}
                </p>
                <div style={{ padding: "20px" }}>
                  <span>USD $ </span>
                  <input
                    onChange={(e) => {
                      let reg = new RegExp("^\\d+$");
                      if (reg.test(e.target.value)) {
                        setPrice(e.target.value);
                      }
                    }}
                    value={selectedPrice}
                    type="number"
                    min={price}
                  />
                </div>
                <PaypalBtns
                  releaseId={id}
                  merchantId={merchantId}
                  isRelease={true}
                  price={selectedPrice}
                />
                <hr />
                <p style={{ fontSize: "12px" }}>
                  purchase payments are remitted to provider of content. you can
                  pay the provider’s asking price, or more. information on
                  revenue share{" "}
                  <Link
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                    to="/us/terms"
                  >
                    <span style={{ fontWeight: "bolder" }}>here</span>
                  </Link>
                </p>
              </div>
            </InfoModal>
          )}
        </div>
      </div>
    </div>
  );
};

export default PosTop;
