import React from "react";
import TopBar from "../topbar/TopBar";
import { Query } from "react-apollo";

import gql from "graphql-tag";
import draftToHtml from "draftjs-to-html";

const GETCONFIG = gql`
  query getConfig {
    getConfig {
      termsConditions
      privacy
      copyright
      why
      email
      founded
    }
  }
`;

const Terms = props => {
  return (
    <div>
      <TopBar {...props} />
      <div style={{ padding: "20px", maxWidth: "667px", margin: "auto" }}>
        <h1>Terms &amp; Conditions</h1>
        <Query query={GETCONFIG}>
          {({ data, loading, error }) => {
            if (loading) return "...";
            if (error) return "Something went wrong :(";

            const { termsConditions } = data.getConfig;
            const terms = draftToHtml(JSON.parse(termsConditions));

            return <div dangerouslySetInnerHTML={{ __html: terms }} />;
          }}
        </Query>
      </div>
    </div>
  );
};

export default Terms;
