import React, { Component } from "react";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";

import { LoaderMain } from "../shared/loaders";
import NewReleasesList from "./NewRelaeasesList";

const GET_NEW_RELEASES = gql`
  query getNewReleases($date: DateTime!) {
    getNewReleases(date: $date) {
      id
      releases {
        id
        title
        type
        publicationDate
        about
        additionalAdminInfo
        artist {
          id
          userName
          firstName
          lastName
        }
      }
    }
  }
`;

class NewReleasesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="newRel-wrapper">
        <h2 className="newRel-heading">new releases</h2>
        <div className="newRel-container">
          <Query query={GET_NEW_RELEASES} variables={{ date: "2019-04-15" }}>
            {({ loading, error, data }) => {
              if (loading) return <LoaderMain />;
              if (error) return null;
              if (!data.getNewReleases) return null;
              const { releases } = data.getNewReleases;
              return <NewReleasesList releases={releases} />;
            }}
          </Query>
        </div>
      </div>
    );
  }
}

export default NewReleasesContainer;
