import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import { artistPath, releasePath } from "../../utils/utils";

import PosBottom from "../pos/PosBottom";

const RELEASE_CREDITS = gql`
  query getRelease($releaseId: ID!) {
    getRelease(releaseId: $releaseId) {
      id
      title
      type
      publicationDate
      produced
      engineered
      mixed
      mastered
      studio
      designed
      photo
      thanks
      additionalCredits
      copyrightNote
      about
      tracks {
        id
        duration
      }
      personnels {
        id
        name
        instrument
      }
      artist {
        id
        userName
      }
      label {
        id
        userName
      }
    }
  }
`;

const ReleaseCredits = props => {
  const releaseId = props.match.params.releaseId;

  useEffect(() => {
    document.documentElement.style.setProperty("--bg", `#000`);
    return () => document.documentElement.style.setProperty("--bg", `#fff`);
  }, []);

  return (
    <Query
      query={RELEASE_CREDITS}
      variables={{ releaseId: parseInt(releaseId) }}
    >
      {({ loading, error, data }) => {
        if (loading) return "...";
        if (error) return `Error! ${error.message}`;
        const release = data.getRelease;

        return (
          <div className="releaseCredits-container pos-wrapper">
            <div className="releaseCredits-header">
              <p className="rc-type">{release.type.toLowerCase()}</p>
              <h1 className="rc-title">{release.title}</h1>
              <Link className="rc-artist" to={artistPath(release.artist)}>
                <h2>{release.artist.userName} {">"}</h2>
              </Link>
            </div>

            <div className="releaseCredits-content">
              <p>
                <span className="t-grey">personnel:</span>
              </p>
              {release.personnels.map(p => (
                <p key={p.id}>
                  {p.name}: {p.instrument.toLowerCase()}
                </p>
              ))}

              <br />

              <p>
                <span className="t-grey">produced: </span>
                {release.produced}
              </p>
              <p>
                <span className="t-grey">engineered: </span>
                {release.engineered}
              </p>
              <p>
                <span className="t-grey">mixed: </span>
                {release.mixed}
              </p>
              <p>
                <span className="t-grey">mastered: </span>
                {release.mastered}
              </p>
              <p>
                <span className="t-grey">studio: </span>
                {release.studio}
              </p>
              <p>
                <span className="t-grey">designed: </span>
                {release.designed}
              </p>
              <p>
                <span className="t-grey">photography: </span>
                {release.photo}
              </p>
              <p>
                <span className="t-grey">additional credits: </span>
                {release.additionalCredits}
              </p>
              <p>
                <span className="t-grey">thanks: </span>
                {release.thanks}
              </p>

              <br />

              <p>{release.about}</p>
              <div style={{ display: "block", borderBottom: "1px dotted" }} />
              <p>{release.copyrightNote}</p>

              <Link
                className="rc-artist-link"
                to={`${releasePath(release)}/credits/tracks`}
              >
                {"track credits >"}
              </Link>
            </div>

            <div className="releaseCredits-footer">
              <PosBottom {...data.getRelease} />
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default ReleaseCredits;
