import React from "react";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";

const RESEND_VERIFICATION = gql`
  mutation resendVerification($email: EmailAddress!) {
    resendUserVerificationEmail(email: $email)
  }
`;

class ResendVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: ""
    };
  }
  render() {
    const { email, message } = this.state;
    return (
      <Mutation
        mutation={RESEND_VERIFICATION}
        onCompleted={data => {
          if (data.resendUserVerificationEmail) {
            this.setState({ message: `Email sent to: ${email}` });
          }
        }}
        onError={error => {
          this.setState({
            error: error.graphQLErrors.map(e => `${e.message} `).toString()
          });
        }}
      >
        {(resend, { loading, error }) => {
          return (
            <>
              {message.length ? (
                <p>{message}</p>
              ) : (
                <form
                  style={{ width: "100%" }}
                  onSubmit={e => {
                    e.preventDefault();
                    resend({ variables: { email: email } });
                  }}
                >
                  {this.state.error && (
                    <p style={{ color: "red" }}>{this.state.error}</p>
                  )}
                  <div className="formRow">
                    <label className="formLabel" htmlFor="firstName">
                      email
                    </label>
                    <input
                      value={email}
                      onChange={e => this.setState({ email: e.target.value })}
                      className="form-control"
                      type="email"
                    />
                  </div>

                  {loading ? (
                    "..."
                  ) : (
                    <button
                      style={{ float: "right" }}
                      type="submit"
                      className="btn-black"
                    >
                      send >
                    </button>
                  )}
                </form>
              )}
            </>
          );
        }}
      </Mutation>
    );
  }
}

export default ResendVerification;
