import React from "react";
import { Link } from "react-router-dom";
// import bg from "../../assets/images/bg1.png";
import UserContext from "../../context/UserContext";
import onResize from "../../utils/resize";
import { Query } from "react-apollo";
import USER from "../../queries/user";
import HomePageFirstTimeVisit from "./HomePageFirstTimeVisit";
import gql from "graphql-tag";
import { LoaderMain } from "../shared/loaders";

const GET_HOME = gql`
  query {
    homePageByWeek(weekStart: "2019-04-15") {
      id
      credits
      labelColor
      picture
      weekStart
      weekEnd
      createdAt
      updatedAt
    }
  }
`;

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showWelcome: false,
      vh: 0,
    };
  }

  componentDidMount() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    onResize(() => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }

  static contextType = UserContext;

  unredadedMsgs = (messages) => {
    return messages.filter((m) => !m.read).filter((m) => m.release !== null)
      .length;
  };

  render() {
    const { showPlayer } = this.context;

    return (
      <Query query={GET_HOME}>
        {({ loading, error, data }) => {
          if (loading) return <LoaderMain />;
          if (error) {
            console.log(`${error.message}`);
            return null;
          }
          const { labelColor } = data.homePageByWeek;

          const isBlack = labelColor === "White_on_Black";

          return (
            <div
              style={{
                backgroundImage: `url('${data.homePageByWeek.picture}')`,
                paddingBottom: `${showPlayer ? "75px" : "0"}`,
              }}
              className={`home-wrapper`}
            >
              <HomePageFirstTimeVisit />
              <div className="home-top" />
              <div className="home-middle">
                <Link
                  to="/new-tracks"
                  className={`home-link ${isBlack ? "black" : ""}`}
                >
                  new tracks
                </Link>
                <Link
                  to="/new-releases"
                  className={`home-link ${isBlack ? "black" : ""}`}
                >
                  new releases
                </Link>
                <Link
                  to="/4x4"
                  className={`home-link ${isBlack ? "black" : ""}`}
                >
                  4x4
                </Link>
              </div>
              <div className="home-footer">
                <Link
                  to="/us"
                  className={`footer-link ${isBlack ? "" : "black"}`}
                >
                  us
                </Link>

                <Query fetchPolicy="network-only" query={USER}>
                  {({ loading, error, data }) => {
                    if (loading) return "Loading...";
                    if (error) {
                      return (
                        <Link
                          to="/login"
                          className={`footer-link ${isBlack ? "" : "black"}`}
                        >
                          log in
                        </Link>
                      );
                    }

                    return (
                      <>
                        {this.context.isLoggedIn ? (
                          <Link
                            to="/you"
                            className={`footer-link ${isBlack ? "" : "black"}`}
                          >
                            you <span className="notification-brackets">(
                            <span>
                              {data &&
                                data.me &&
                                this.unredadedMsgs(data.me.alertMessage)}
                            </span>
                            )</span>
                          </Link>
                        ) : (
                          <Link
                            to="/login"
                            className={`footer-link ${isBlack ? "" : "black"}`}
                          >
                            log in
                          </Link>
                        )}
                      </>
                    );
                  }}
                </Query>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Home;
