import React from 'react';

import { Query } from 'react-apollo';
import {gql} from 'apollo-boost'
import { LoaderMain } from '../shared/loaders';
import PlayerContext from '../../context/PlayerContext';
import Player from '../player/Player';
import { getTrackIndex } from '../../utils/utils';

const GET_RELEASE = gql`
  query getRelease($releaseId: ID!) {
    getRelease(releaseId: $releaseId) {
      id
      title
      type
      publicationDate
      cover
      label {
        id
        userName
      }
      artist {
        id
        userName
        firstName
        lastName
      }
      tracks {
        id
        title
        duration
        awsKey
        awsBucketName
        release{
          id
          cover
          title
          type
          artist{
            id
            userName
            firstName
            lastName
          }
        }
        files {
          url
        }
      }
    }
  }
`;
class PlaybackContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  static contextType = PlayerContext

  componentDidMount(){
  }

  render() {
    const { releaseId, trackId } = this.props.match.params

    return (

      <div style={{marginTop: "50px"}} className="palyback-wrapper">
          <Query query={GET_RELEASE} variables={{releaseId: releaseId}}>
            {({ loading, error, data }) => {
              if (loading) return <LoaderMain/>;
              if (error) return `Error! ${error.message}`;

              const trackIndex = getTrackIndex(data.getRelease.tracks, trackId)

              return (
                  <Player playlist={data.getRelease.tracks} trackIndex={trackIndex}/>
              );
            }}
          </Query>



      </div>


   );
  }
}

export default PlaybackContainer;

