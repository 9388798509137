import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InfoModal from "../../shared/InfoModal";

// import ALLOWED_COUNTRIES from "../../../data/allowed-countries";
import COUNTRIES from "../../../data/countries";

const RegisterForm = props => {
  const { handleSubmit, error, loading } = props;

  // TODO: this can be also handled server-side in the graphql api
  if (
    error &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].message === "email must be unique"
  )
    error.graphQLErrors[0].message = "email assigned to an existing acct";

  const [zipModal, showZipModal] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          name: "",
          userName: "",
          zip: "",
          password: "",
          confirm: "",
          accept: false,
          country: "United States"
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("ivalid email address")
            .required("required"),
          name: Yup.string().required(),
          userName: Yup.string().required("username is required"),
          country: Yup.string()
            .test(
              "as",
              "at present, platform access is unavailable to your territory",
              val => {
                return true;
              }
            )
            .required(),
          password: Yup.string()
            .required("password required")
            .min(6, "password too short - should be 6 chars minimum."),
          confirm: Yup.string()
            .oneOf([Yup.ref("password"), null], "passwords must match")
            .required("password confirmation required"),
          accept: Yup.bool()
            .test(
              "acceptTerms",
              "registration requires agreement",
              value => value === true
            )
            .required("registration requires agreement")
        })}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue, validateField, setFieldTouched }) => {
          return (
            <Form style={loading ? { opacity: 0.2 } : {}} className="loginForm">
              {error && error.graphQLErrors[0] ? (
                <p className="text-right" style={{ color: "red" }}>
                  {error.graphQLErrors[0].message}
                </p>
              ) : (
                ""
              )}
              <div className="formRow">
                <label className="formLabel" htmlFor="name">
                  name
                </label>
                <Field name="name" type="text" />
                <ErrorMessage
                  name="name"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="userName">
                  user
                </label>
                <Field name="userName" type="text" />
                <ErrorMessage
                  name="userName"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="country">
                  country
                </label>
                <Field
                  component="select"
                  name="country"
                  onChange={e => {
                    setFieldTouched("country", true);
                    setFieldValue("country", e.target.value);
                    // validateField("country");
                  }}
                  type="text"
                >
                  {COUNTRIES.map((country, i) => (
                    <option key={i} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="country"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="zip">
                  zip{" "}
                  <button
                    type="button"
                    className="btn-bare"
                    onClick={e => showZipModal(true)}
                    tabIndex="-1"
                  >
                    [?]
                  </button>
                </label>
                <Field name="zip" type="text" />
                <ErrorMessage
                  name="zip"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="email">
                  email
                </label>
                <Field name="email" type="email" />
                <ErrorMessage
                  name="email"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="password">
                  pw*
                </label>
                <Field name="password" type="password" />
                <ErrorMessage
                  name="password"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <label className="formLabel" htmlFor="confirm">
                  confirm pw*
                </label>
                <Field name="confirm" type="password" id="confirm" />
                <ErrorMessage
                  name="confirm"
                  className="formError"
                  component="div"
                />
              </div>
              <div className="formRow">
                <p style={{ fontSize: "0.75rem" }}>
                  *suggested: lowercase letter(s). capital letter(s). number(s).
                  symbol(s).
                </p>
              </div>
              <div className="formRow">
                <div className="formCol mgt10">
                  <Field
                    id="accept"
                    name="accept"
                    component="input"
                    type="checkbox"
                    checked={values.accept}
                  />
                  <label className="checkbox-label" htmlFor="accept">
                    I accept{" "}
                    <Link
                      to="/us/terms"
                      style={{
                        fontSize: "1rem",
                        color: "#000",
                        fontWeight: "bold"
                      }}
                      tabIndex={-1}
                    >
                      t&amp;c’s
                    </Link>
                  </label>
                  <ErrorMessage
                    name="accept"
                    className="formError"
                    component="div"
                    style={{ bottom: "-25px" }}
                  />
                </div>
                <div className="formCol">
                  <button type="submit" className="formBtn">
                    register >
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      {zipModal && (
        <InfoModal onHide={e => showZipModal(false)} header={<p>zip code</p>}>
          <p>
            zip code is requested in order to present future location-based
            features.
          </p>
        </InfoModal>
      )}
    </>
  );
};

export default RegisterForm;
