import React, { Component } from "react";
import { Link } from "react-router-dom";
import { releasePath } from "../../utils/utils";

export const PurchasedSliderItem = props => {
  const { type, artist, title, cover, /* id, */ release } = props;
  return (
    <div className="sliderItem">
      <Link
        to={release.artist ? releasePath(release) : "/you/archive"}
        style={{ cursor: release.artist ? "pointer" : "auto" }}
      >
        <div
          style={{ backgroundImage: `url("${props.picture}")` }}
          className="sliderItem-cover"
        >
          <img style={{ width: "100px", height: "100px" }} src={cover} alt="" />
        </div>
        <p className="sliderItem-artist">{artist.userName}</p>
        {type === "track" ? (
          <>
            <p className="sliderItem-artist">{release.title}</p>
            <p className="sliderItem-type">{title}</p>
          </>
        ) : (
          <>
            <p className="sliderItem-artist">{release.title}</p>
            <p className="sliderItem-type">{release.type}</p>
          </>
        )}
      </Link>
    </div>
  );
};

const EmptySliderItem = () => (
  <div
    style={{ width: "100px", height: "100px", border: "1px dashed #5a5a5acc" }}
    className="sliderItem empty"
  />
);



class PurchasedSlider extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef()
    }
    scrollLeft=e=>{
        let element = this.scrollRef.current
        element.scrollLeft = element.scrollLeft - 200
    }
    scrollRight=e=>{
        let element = this.scrollRef.current
        element.scrollLeft = element.scrollLeft + 200
    }
    render() {
        const collection = this.props.collection
        return (
            <div className="slider-container" style={{ position: "relative" }}>
                <div className="scroll">
              <span className="scroll-left"
                    onClick={this.scrollLeft}
              > &lt;</span> &nbsp; <span className="scroll-right"
                                         onClick={this.scrollRight}
                >&gt;</span>
                </div>
                <div className="slider-inner" ref={this.scrollRef}>
                    {collection.length ? (
                        collection.map((item, i) => {
                            return <PurchasedSliderItem key={i} {...item} />;
                        })
                    ) : (
                        <EmptySliderItem />
                    )}
                </div>
            </div>
        );
    }
}

export default PurchasedSlider;
