import React, { Component } from "react";

import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import queryString from "query-string";
import { LoaderMain } from "../shared/loaders";

import PlayerContext from "../../context/PlayerContext";
import PosPage from "./PosPage";

const GET_RELEASE = gql`
  query getRelease($releaseId: ID!) {
    getRelease(releaseId: $releaseId) {
      id
      title
      type
      publicationDate
      cover
      isAllowed
      price
      label {
        id
        userName
      }
      artist {
        id
        userName
        firstName
        lastName
      }
      tracks {
        id
        title
        duration
        videoId
        publicationDate
        price
        awsKey
        awsBucketName
        release {
          id
          cover
          title
          type
          artist {
            id
            userName
            firstName
            lastName
          }
        }
        files {
          url
        }
      }
    }
  }
`;

class PocContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = PlayerContext;
  shouldStartPlay = queryString.parse(this.props.location.search).start;

  render() {
    const releaseId = this.props.match.params.releaseId;

    return (
      <Query
        query={GET_RELEASE}
        variables={{ releaseId: parseInt(releaseId) }}
        onCompleted={data => {}}
      >
        {release => {
          if (release.loading) return <LoaderMain />;
          if (release.error) return `Error! ${release.error.message}`;

          return <PosPage {...this.props} data={release.data} />;
        }}
      </Query>
    );
  }
}

export default PocContainer;
