import React, { Component } from "react";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import FxFPlayer from "./FxFPlayer";

import TopbarWithSearch from "../shared/TopBarWithSearch";
import PlayerContext from "../../context/PlayerContext";
import FxFGrid from "./FxFGrid";

const GET_4x4 = gql`
  query get4x4($date: DateTime) {
    get4x4(date: $date) {
      id
      releases {
        id
        title
        cover
        type
        isAllowed
        artist {
          id
          userName
        }
        tracks {
          id
          title
          duration
          awsKey
          awsBucketName
          release{
            id
            cover
            title
            type
            artist{
              id
              userName
              firstName
              lastName
            }
          }
          files {
            url
          }
        }
      }
    }
  }
`;

class FourBtFourContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFxFPlayer: false
    };
  }

  static contextType = PlayerContext;

  toggleshowFxFPlayer = () => {
    this.setState({ showFxFPlayer: true });
  };

  preparePlaylist = releases => {
    return releases.map(r => ({
      id: r.tracks[0].id,
      title: r.tracks[0].title,
      videoId: r.tracks[0].videoId,
      files: r.tracks[0].files,
      __typename: "Track",
      release: {
        id: r.id,
        title: r.title,
        cover: r.cover,
        type: r.type,
        __typename: "Release",
        artist: {
          id: r.artist.id,
          userName: r.artist.userName,
          __typename: "User"
        }
      }
    }));
  };

  componentDidMount() {
    this.context.togglePlayer(false);
    if (this.context.isPlaying) {
      this.setState({ showFxFPlayer: true });
    }
  }

  componentWillUnmount() {
    if (this.state.showFxFPlayer) {
      this.context.togglePlayer(true);
    }
  }

  render() {
    return (
      <>
        <TopbarWithSearch style={{ backgroundColor: "#fff" }} theme={"dark"} />
        <div className="fxf-wrapper">
          <Query query={GET_4x4} variables={{ date: "2019-04-15" }}>
            {({ loading, error, data, refetch }) => {
              if (loading) return <div className="spinner" />;
              if (error) return `Error! ${error.message}`;
              if (!data.get4x4) return null;
              const { releases } = data.get4x4;
              return (
                <>
                  <FxFGrid
                    toggleshowFxFPlayer={this.toggleshowFxFPlayer}
                    releases={releases}
                    {...this.props}
                  />
                  {this.state.showFxFPlayer ? (
                    !this.context.playerFull ? (
                      <FxFPlayer releases={releases} />
                    ) : null
                  ) : null}
                </>
              );
            }}
          </Query>
        </div>
      </>
    );
  }
}

export default FourBtFourContainer;
