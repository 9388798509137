import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_TRACK = gql`
  query getTrack($trackId: ID!) {
    getTrack(trackId: $trackId) {
      id
      title
      files {
        url
        type
        name
      }
    }
  }
`;

const DownloadPurchasedTrack = ({
  id,
  content = "Download",
  showTitle = true
}) => {
  return (
    <Query query={GET_TRACK} variables={{ trackId: id }}>
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        let files = data.getTrack.files,
            file = null;
        if(files && files.length){
            file = files.find(f=>f.type==="mp3").url
        }
          return (
          <a
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "5px"
            }}
            className="posTrack-purchase"
            target="_blank"
            rel="noopener noreferrer"
            download={true}
            href={file}
          >
            {content}
            {showTitle ? data.getTrack.title : null}
          </a>
        );
      }}
    </Query>
  );
};

export default DownloadPurchasedTrack;
