import React, { Component } from "react";
import PlayerContext from "../../context/PlayerContext";
import { IcoPlay, IcoPause, IcoRewind, IcoForward } from "../shared/icons";
import TrackIndex from "../playback/TrackIndex";

class AppPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      duration: 0,
      currentTime: 0
    };
    this.timeline = React.createRef();
    this.handle = React.createRef();
  }

  static contextType = PlayerContext;

  setDuration = () => this.setState({ duration: this.context.track.duration });
  trackProgress = () =>
    this.setState({ currentTime: this.context.track.currentTime });
  updatePosition = () => {
    let ratio = this.context.track.currentTime / this.context.track.duration;
    let position = this.timeline.current.offsetWidth * ratio;
    this.positionHandle(position);
  };

  componentDidMount() {
    this.setState({ currentTime: this.context.track.currentTime });

    this.context.track.addEventListener(
      "loadedmetadata",
      this.setDuration,
      true
    );
    this.context.track.addEventListener("timeupdate", this.trackProgress, true);
    this.onUpdatePos();
  }

  componentWillUnmount() {
    this.context.track.removeEventListener(
      "timeupdate",
      this.updatePosition,
      true
    );
    this.context.track.removeEventListener(
      "loadedmetadata",
      this.setDuration,
      true
    );
    this.context.track.removeEventListener(
      "timeupdate",
      this.trackProgress,
      true
    );
  }

  onUpdatePos = () => {
    this.context.track.addEventListener(
      "timeupdate",
      this.updatePosition,
      true
    );
  };

  positionHandle = position => {
    let timelineWidth =
      this.timeline.current.offsetWidth - this.handle.current.offsetWidth;
    let handleLeft = position - this.timeline.current.offsetLeft;
    if (handleLeft >= 0 && handleLeft <= timelineWidth) {
      this.handle.current.style.marginLeft = handleLeft + "px";
    }
    if (handleLeft < 0) {
      this.handle.current.style.marginLeft = "0px";
    }
    if (handleLeft > timelineWidth) {
      this.handle.current.style.marginLeft = timelineWidth + "px";
    }
  };

  mouseMove = e => {
    const duration = this.context.track.duration || 0;
    this.positionHandle(e.pageX);
    this.context.track.currentTime =
      (e.pageX / this.timeline.current.offsetWidth) * duration;
  };

  mouseUp = e => {
    window.removeEventListener("mousemove", this.mouseMove);
    window.removeEventListener("mouseup", this.mouseUp);
  };

  mouseDown = e => {
    window.addEventListener("mousemove", this.mouseMove);
    window.addEventListener("mouseup", this.mouseUp);
  };

  render() {
    const {
      playNextTrack,
      playPrevTrack,
      playbackPaused,
      pause,
      resume
    } = this.context;
    const { playlist, currentTrackIndex, showPlayer } = this.props;
    const curentTrack = playlist[currentTrackIndex];

    if (!showPlayer) return null;
    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          backgroundColor: "#fff",
          width: "100%"
        }}
        className="appPlayer-containe"
      >
        <div className="fxfPlayer">
          <div
            className="fxfPlayer-timeline"
            onClick={this.mouseMove}
            ref={this.timeline}
          >
            <div
              className="fxfPlayer-handle"
              onMouseDown={this.mouseDown}
              ref={this.handle}
            />
          </div>

          <div
            className="fxfPlayer-info"
            onClick={e => this.context.togglePlayerExpandedPlayer(true)}
          >
            {curentTrack && (
              <>
                <p className="fxfTrack-title">
                  <span>
                    {" "}
                    <TrackIndex
                      trackIndex={this.context.currentTrackIndex + 1}
                    />
                  </span>{" "}
                  {curentTrack.title}
                </p>
                <p className="fxfTrack-release">
                  {curentTrack.release.artist.userName} |{" "}
                  {curentTrack.release.title}{" "}
                  <span>{curentTrack.release.type}</span>
                </p>
              </>
            )}
          </div>
          <div className="fxfPlayer-controls">
            <button
              onClick={e => {
                this.positionHandle(0);
                playPrevTrack();
              }}
            >
              <IcoRewind className={"fxfPlayer-ico"} />
            </button>

            {playbackPaused ? (
              <button onClick={resume}>
                <IcoPlay className={"fxfPlayer-ico"} />
              </button>
            ) : (
              <button onClick={pause}>
                <IcoPause className={"fxfPlayer-ico"} />
              </button>
            )}

            <button
              onClick={e => {
                this.positionHandle(0);
                playNextTrack();
              }}
            >
              <IcoForward className={"fxfPlayer-ico"} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AppPlayer;
