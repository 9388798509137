import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { releasePath, artistPath } from "../../utils/utils";

class SearchDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "artist"
    };
  }

  componentDidMount() {}

  setTab = name => this.setState({ activeTab: name });

  render() {
    const { artists, releases, tracks, preReleases } = this.props.results;
    const { activeTab } = this.state;
    return (
      <div className="searchDetails">
        <div className="searchDetails-nav">
          <button
            onClick={() => this.setTab("artist")}
            className={`labelList-btn searchDetails-btn ${
              this.state.activeTab === "artist" ? "active" : ""
            }`}
          >
            artists{" "}
            <span className="searchdetails-num">({artists.length})</span>
          </button>

          <button
            onClick={() => this.setTab("release")}
            className={`labelList-btn searchDetails-btn ${
              this.state.activeTab === "release" ? "active" : ""
            }`}
          >
            releases{" "}
            <span className="searchdetails-num">
              ({[...releases, ...preReleases].length})
            </span>
          </button>

          <button
            onClick={() => this.setTab("track")}
            className={`labelList-btn searchDetails-btn ${
              this.state.activeTab === "track" ? "active" : ""
            }`}
          >
            tracks <span className="searchdetails-num">({tracks.length})</span>
          </button>
        </div>

        <div className="searchDetails-content">
          {activeTab === "artist" && (
            <div className="searchDetails-tab">
              {artists
                .sort(function(a, b) {
                  if (a.userName < b.userName) {
                    return -1;
                  }
                  if (a.userName > b.userName) {
                    return 1;
                  }
                  return 0;
                })
                .map(a => (
                  <a
                    style={{ display: "block" }}
                    href={artistPath(a)}
                    onClick={e => {
                      e.preventDefault();
                      this.props.history.push(artistPath(a));
                      this.props.closeSearch();
                    }}
                    key={a.id}
                  >
                    <div className="searchDetails-item">
                      <div
                        style={{ backgroundImage: `url("${a.picture}")` }}
                        className="searchDetails-image"
                      />
                      <div>
                        <p style={{ margin: "8px 0 5px" }}>{a.userName}</p>
                        <p style={{ fontSize: "12px", margin: 0 }}>
                          {` ${a.city}, ${a.state}, ${a.country}`}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
            </div>
          )}

          {activeTab === "release" && (
            <div className="searchDetails-tab">
              {[...releases, ...preReleases]
                .sort(function(a, b) {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                })
                .map(a => (
                  <a
                    style={{ display: "block" }}
                    href={releasePath(a)}
                    onClick={e => {
                      e.preventDefault();
                      this.props.history.push(releasePath(a));
                      this.props.closeSearch();
                    }}
                    key={a.id}
                  >
                    <div className="searchDetails-item">
                      <div
                        style={{ backgroundImage: `url("${a.cover}")` }}
                        className="searchDetails-image"
                      />
                      <div>
                        <p style={{ margin: "8px 0 5px" }}>{a.title}</p>
                        <p style={{ fontSize: "12px", margin: 0 }}>
                          {a.artist.userName}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
            </div>
          )}

          {activeTab === "track" && (
            <div className="searchDetails-tab">
              {tracks
                .sort(function(a, b) {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                })
                .map(a => (
                  <a
                    style={{ display: "block" }}
                    href={`${releasePath(a.release)}?track=${a.id}&start=true`}
                    onClick={e => {
                      e.preventDefault();
                      this.props.history.push(
                        `${releasePath(a.release)}?track=${a.id}&start=true`
                      );
                      this.props.closeSearch();
                    }}
                    key={a.id}
                  >
                    <div className="searchDetails-item">
                      <div
                        style={{ backgroundImage: `url("${a.release.cover}")` }}
                        className="searchDetails-image"
                      />
                      <div style={{ overflow: "hidden" }}>
                        <p
                          style={{ margin: "8px 0 5px", whiteSpace: "nowrap" }}
                        >
                          {a.title}
                        </p>
                        <p style={{ fontSize: "12px", margin: 0 }}>
                          {a.release.artist.userName} | {a.release.title}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(SearchDetails);
