export default (fn) => {

    window.addEventListener("resize", resizeThrottler, false);
  
    var resizeTimeout;
    function resizeThrottler() {
      if ( !resizeTimeout ) {
        resizeTimeout = setTimeout(function() {
          resizeTimeout = null;
          fn();       
         }, 66);
      }
    }
  
  }