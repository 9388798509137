import React, { useState, useContext } from "react";
import PosPageFirstTimeVisit from "../pos/PosFirstTimeSupport";
import FirstTimeSupport from "../pos/FirstTimeAlert";
import AlertDialog from "../alerts/AlertDialog";
import UserContext from "../../context/UserContext";
import history from "./../../history";

const ArtistHeader = (props) => {
  const { userName, picture, city, state, country, id, paypal } = props;
  const [showAlert, toggleAlert] = useState(false);
  const { me } = useContext(UserContext);

  function onsubmit() {
    console.log("in submit:::");
    history.push("/login");
  }

  return (
    <div className="artistHeader">
      <div className="artistHeader-left">
        <p className="artistHeader-name">{userName}</p>
        <p className="labelHeader-location">
          {city}, {state}, {country}
        </p>

        {!me && (
          <div className="artistHeader-btns">
            <button onClick={onsubmit} className="pos-btn support-btn">
              support $
            </button>
            <br />
            <br />

            <button onClick={onsubmit} className="pos-btn">
              alerts /!\
            </button>
          </div>
        )}

        {me && me.verified && (
          <div className="artistHeader-btns">
            {paypal && paypal.merchantIdInPayPal && (
              <PosPageFirstTimeVisit
                merchantId={id}
                artistId={id}
                username={userName}
                supportTier={me.supportTier}
              />
            )}
            <br />
            <br />

            <FirstTimeSupport
              btn={
                <button onClick={(e) => toggleAlert(true)} className="pos-btn">
                  alerts /!\
                </button>
              }
            />
          </div>
        )}
      </div>
      <div className="artistHeader-right">
        <p className="artistHeader-name artist-name-lg">{userName}</p>
        <div
          className="artistHeader-cover"
          style={{ backgroundImage: `url("${picture}")` }}
        />
      </div>
      {showAlert && <AlertDialog onHide={(e) => toggleAlert(false)} />}
    </div>
  );
};

export default ArtistHeader;
