import React from "react";
import { Link } from "react-router-dom";
// import { IcoUp } from "../shared/icons";
import { releasePath } from "../../utils/utils";

const PosFooter = ({ release }) => {
  return (
    <div className="posFooter">
      <Link to={`${releasePath(release)}/credits`} className="posFooter-link">
        credits >
      </Link>
      {/* <button className="posFooter-scroll">
        <IcoUp className="posFooter-scrollUp" />
      </button> */}
    </div>
  );
};

export default PosFooter;
