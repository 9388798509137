import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { LoaderMain } from "../shared/loaders";
// import DownloadPurchasedTrack from "./DownloadPurchasedTrack";
import USER from "../../queries/user";

const MERCHANT_ID = gql`
  query appLabelArtistDetail($id: ID!) {
    appLabelArtistDetail(id: $id) {
      id
      userName
      paypal {
        merchantId
        merchantIdInPayPal
        isEmailConfirmed
        permissionsGranted
        accountStatus
      }
    }
  }
`;

const CREATEORDER = gql`
  mutation paypalCreatePurchaseOrder(
    $merchantIdInPayPal: String!
    $releaseId: ID!
    $trackId: ID
    $price: PositiveInt
    $userId: PositiveInt
  ) {
    paypalCreatePurchaseOrder(
      merchantIdInPayPal: $merchantIdInPayPal
      releaseId: $releaseId
      trackId: $trackId
      price: $price
    ) {
      id
      status
      links {
        href
      }
      details {
        issue
        description
        message
      }
    }
  }
`;

const CREATE_SUPPORT = gql`
  mutation paypalCreateSupportOrder(
    $amount: PositiveInt!
    $merchantIdInPayPal: String!
    $userId: PositiveInt
  ) {
    paypalCreateSupportOrder(
      amount: $amount
      merchantIdInPayPal: $merchantIdInPayPal
      userId: $userId
    ) {
      id
      status
      links {
        rel
      }
      details {
        issue
        description
        message
      }
    }
  }
`;

const APPROVE = gql`
  mutation paypalHandleApprove($paypalOrderId: String!) {
    paypalHandleApprove(paypalOrderId: $paypalOrderId) {
      id
      merchantIdInPayPal
      amount
      type
      orderStatus
      paypalOrderId
      paypalStatus
      purchaser {
        id
        userName
      }
      paymentReceiver {
        id
        userName
      }
      track {
        id
      }
      release {
        id
      }
      description
      createdAt
      updatedAt
    }
  }
`;

const PaypalBtns = ({
  merchantId,
  trackId,
  releaseId,
  client,
  isSupport,
  supportVal,
  price,
  handleChangeSupported,
}) => {
  const [showSucces, toggleShowSucces] = React.useState(false);
  const [paypalOrderId, setPaypalOrderId] = React.useState(null);
  const [proccesing, setProccesing] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [showOrderFail, setShowOrderFail] = React.useState(false);
  if (showError) {
    return (
      <div style={{ border: "1px solid #ccc", textAlign: "center" }}>
        <p style={{ color: "red" }}>
          there was an error processing your payment
        </p>
        <span
          style={{ margin: "10px", border: "1px solid #000" }}
          onClick={e => setShowError(false)}
          className="btn-black"
        >
          Try again
        </span>
      </div>
    );
  }

  if (showOrderFail) {
    return (
      <div style={{ border: "1px solid #ccc", textAlign: "center" }}>
        <p style={{ color: "red" }}>
          { showOrderFail }
        </p>
        <span
          style={{ margin: "10px", border: "1px solid #000" }}
          onClick={e => setShowOrderFail(false)}
          className="btn-black"
        >
          Try again
        </span>
      </div>
    );
  }

  return (
    <div
      style={{ minHeight: `${showSucces ? "auto" : "300px"}` }}
      className="paypalBtnContainer"
    >
      <Query
        fetchPolicy="network-only"
        query={MERCHANT_ID}
        variables={{ id: merchantId }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoaderMain />;
          if (error) return `Error! ${error.message}`;
          console.log(data);
          if (!data.appLabelArtistDetail.paypal)
            return (
              <p style={{ color: "red" }}>
                {isSupport
                  ? "support is not available for this artist"
                  : "purchase not avaliable for this provider"}
              </p>
            );

          const { merchantIdInPayPal } = data.appLabelArtistDetail.paypal;
            console.log(merchantIdInPayPal)
            console.log(data)
            console.log("Debugging merchant id")
          if (!data.appLabelArtistDetail || !merchantIdInPayPal)
            return (
              <p style={{ color: "red" }}>
                {isSupport
                  ? "support is not available for this artist"
                  : "purchase not avaliable for this provider"}
              </p>
            );
          if (proccesing) return <LoaderMain />;
          return (
            <div>
              {showSucces ? (
                <div>
                  <hr />
                  <p style={{ fontWeight: "bold", color: "green" }}>
                    payment confirmed
                  </p>
                  <hr />
                  <p>transaction id: {paypalOrderId}</p>
                  {/* {isRelease && <p>release link</p>}
                  {isTrack && <DownloadPurchasedTrack id={trackId} />}
                  {isSupport && <p>Thanks for support</p>} */}

                  <hr />
                </div>
              ) : (
                <PayPalButton
                  createOrder={async data => {
                    return await client
                      .mutate({
                        mutation: isSupport ? CREATE_SUPPORT : CREATEORDER,
                        variables: isSupport
                          ? { amount: supportVal, merchantIdInPayPal, userId: Number(merchantId) }
                          : {
                              userId: Number(merchantId),
                              merchantIdInPayPal,
                              releaseId,
                              trackId,
                              price: price
                            }
                      })
                      .then(data => {
                        console.log('Create order data', data)
                        if(isSupport) {
                          if(data.data.paypalCreateSupportOrder && data.data.paypalCreateSupportOrder.id)
                            return data.data.paypalCreateSupportOrder.id
                          else {
                            // setShowError(true)
                            setShowOrderFail(data.data.paypalCreateSupportOrder.details && data.data.paypalCreateSupportOrder.details.length ? data.data.paypalCreateSupportOrder.details[0].description : 'could not create paypal support order for the artist')
                            return false
                          }
                        } else {
                          if(data.data.paypalCreatePurchaseOrder && data.data.paypalCreatePurchaseOrder.id) {
                            return data.data.paypalCreatePurchaseOrder.id
                          } else {
                            // setShowError(true)
                            setShowOrderFail(data.data.paypalCreateSupportOrder.details && data.data.paypalCreateSupportOrder.details.length ? data.data.paypalCreateSupportOrder.details[0].description : 'could not create paypal support order for the artist')
                            return false
                          }
                        }
                      })
                      .catch(error => {
                        setShowOrderFail(error.message.replace('GraphQL error: ', ''))
                        console.log('cant create order:: ', error.message);
                      });
                  }}
                  onApprove={data => {
                    setProccesing(true);
                    return client
                      .mutate({
                        mutation: APPROVE,
                        refetchQueries: () => [{ query: USER }],
                        variables: { paypalOrderId: data.orderID }
                      })
                      .then(data => {
                        if (
                          data.data.paypalHandleApprove.orderStatus ===
                          "Completed"
                        ) {
                          setProccesing(false);
                          let paypalOrderId = data.data.paypalHandleApprove.paypalOrderId;
                          try{
                              paypalOrderId = data.data.paypalHandleApprove.description.purchase_units[0].payments.captures[0].id
                              setPaypalOrderId(
                                  paypalOrderId
                              );
                              toggleShowSucces(true);
                              handleChangeSupported();
                            }catch (e){
                              // setShowError(true)
                              console.log("Error in caputre id")
                              console.log( data.data.paypalHandleApprove.description)
                              setShowOrderFail(data.data.paypalHandleApprove.description.details[0].description)
                            }
                        } else {
                          setProccesing(false);
                          // setShowError(true);
                          setShowOrderFail(data.data.paypalHandleApprove.description.details[0].description)
                        }
                      })
                      .catch(error => {
                        setShowOrderFail(error.message)
                      });
                  }}
                  options={{
                    clientId: process.env.REACT_APP_PAYPAL,
                    "enable-funding": "venmo"
                  }}
                />
              )}
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default withApollo(PaypalBtns);
