import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import UserPageDetailsContainer from "./UserPageDetailsContainer";
import ChangePasswordContainer from "./ChangePasswordContainer";
import UserArchiveContainer from "./archive/UserArchiveContainer";
import UserContext from "../../context/UserContext";
import { AUTH_TOKEN } from "../../constants";
import AlertsContainer from "./AlertsContainer";
import { withApollo } from "react-apollo";

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = UserContext;

  logout = () => {
    this.props.client.clearStore().then(e => {
      localStorage.removeItem(AUTH_TOKEN);
      sessionStorage.removeItem(AUTH_TOKEN);
      this.context.logout();
      this.props.history.replace("/");
    });
  };

  render() {
    return (
      <div
        style={{ maxWidth: "667px", margin: "auto" }}
        className="userPage-wrapper"
      >
        <Switch>
          <Route
            exact
            path="/you/change-password"
            component={ChangePasswordContainer}
          />
          <Route path="/you/archive" component={UserArchiveContainer} />
          <Route exact path="/you/alerts" component={AlertsContainer} />

          <Route
            render={props => (
              <UserPageDetailsContainer {...this.props} logout={this.logout} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withApollo(UserPage);
