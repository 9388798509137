// eslint-disable no-unused-vars
import { useContext } from "react";
import PlayerContext from "../../context/PlayerContext";

const TrackIndex = props => {
  const player = useContext(PlayerContext);

  return player.isFxfActive ? "1. " : `${props.trackIndex}.`;
};

export default TrackIndex;
