import React from "react";
import dotBg from "../../assets/images/dottedbg.png";
import { IcoX } from "../shared/icons";
import AlertsControls from "./AlertsControls";

const AlertDialog = (props) => {

  return (
    <div
      style={{ backgroundImage: `url("${dotBg}")` }}
      className="alert-wrapper"
    >
      <div className="alert-backdrop">
        <div className="alert">
          <div className="alert-header">
            <p className="alert-title">artist alert</p>
            <button
              onClick={props.onHide}
              className="close-modal"
            >
              <IcoX />
            </button>
          </div>

          <div className="alert-body">
            <AlertsControls artistId={props.artistId}/>
          </div>
          <div className="alert-footer" />
        </div>
      </div>
    </div>
  );
};

export default AlertDialog;
