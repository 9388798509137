import React from "react";
import { Mutation } from "react-apollo";

import { IcoCheck } from "../shared/icons";

import {GET_ALERTS, DELETE_ALERT, CREATE_ALERT} from "./alertsQueries";


const SetAlerts = props => {
  const { releaseAlert, trackAlert } = props;
  const artistId = props.match.params.artistId;

  return (
    <div className="alertsSelect">
      <Mutation
        mutation={trackAlert ? DELETE_ALERT : CREATE_ALERT}
        awaitRefetchQueries={true}
        refetchQueries={() => [
          {
            query: GET_ALERTS
          }
        ]}
      >
        {setAlert => (
          <button
            type="button"
            onClick={e =>
              setAlert({
                variables: trackAlert
                  ? { alertId: trackAlert.alertId }
                  : { type: "Track", artistId: artistId }
              })
            }
            className={`alertSelect-item ${trackAlert && "active"}`}
          >
            <span>
              <IcoCheck />
            </span>
            <p>new track</p>
          </button>
        )}
      </Mutation>
      <Mutation
        mutation={releaseAlert ? DELETE_ALERT : CREATE_ALERT}
        awaitRefetchQueries={true}
        refetchQueries={() => [
          {
            query: GET_ALERTS
          }
        ]}
      >
        {setAlert => (
          <button
            type="button"
            onClick={e =>
              setAlert({
                variables: releaseAlert
                  ? { alertId: releaseAlert.alertId }
                  : { type: "Release", artistId: artistId }
              })
            }
            className={`alertSelect-item ${releaseAlert && "active"}`}
          >
            <span>
              <IcoCheck />
            </span>
            <p>new release</p>
          </button>
        )}
      </Mutation>
    </div>
  );
};

export default SetAlerts;
