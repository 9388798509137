import gql from "graphql-tag";

const USER = gql`
  query ME {
    me {
      id
      userName
      about
      state
      city
      picture
      email
      role
      country
      supportTier
      token
      verified
      purchasedTracks {
        id
        track {
          id
        }
      }
      purchasedReleases {
        id
        release {
          id
        }
      }
      alertMessage {
        id
        read
        release {
          id
        }
      }
    }
  }
`;

export default USER;
