import React from "react";

const LabelHeader = props => {
  const { picture, userName, about, state, city } = props;
  return (
    <div className="labelHeader">
      <div className="labelHeader-top">
        <div className="labelHeader-heading">
          <p style={{ fontSize: "1.75rem" }} className="labelHeader-name">
            {userName}
          </p>
          <p className="labelHeader-location">
            {city}, {state}
          </p>
        </div>
        <div
          style={{ backgroundImage: `url("${picture}")` }}
          className="labelHeader-picture"
        />
      </div>
      <p className="labelHeader-description">{about}</p>
    </div>
  );
};

export default LabelHeader;
