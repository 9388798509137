import React, { Component } from "react";
import { Route, Switch, NavLink } from "react-router-dom";

import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import { LoaderMain } from "../shared/loaders";
import LabelHeader from "./LabelHeader";
import LabelList from "./LabelList";
import ArtistList from "./LabelArtistsList";
import { labelPath } from "../../utils/utils";

const GET_LABEL = gql`
  query appLabelArtistDetail($id: ID!) {
    appLabelArtistDetail(id: $id) {
      id
      userName
      firstName
      lastName
      picture
      about
      city
      state
      preReleases {
        id
        status
        title
        cover
        publicationDate
        type
        artist {
          id
          userName
          firstName
          lastName
        }
      }
      release {
        id
        status
        title
        cover
        publicationDate
        type
        artist {
          id
          userName
          firstName
          lastName
        }
      }
      artist {
        id
        userName
        firstName
        lastName
        picture
      }
    }
  }
`;

class LabelPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const artistId = this.props.match.params.labelId;
    return (
      <div className="labelWrapper">
        <Query query={GET_LABEL} variables={{ id: artistId }}>
          {({ loading, error, data }) => {
            if (loading) return <LoaderMain />;
            if (error) return `Error! ${error.message}`;
            const { release, preReleases, artist } = data.appLabelArtistDetail;

            return (
              <div className="labelContainer">
                <LabelHeader {...data.appLabelArtistDetail} />
                <div className="label-content">
                  <div className="labelLists-links">
                    <NavLink
                      exact
                      to={labelPath(data.appLabelArtistDetail)}
                      className="labelList-btn"
                      activeClassName="active"
                    >
                      releases
                    </NavLink>

                    <NavLink
                      to={`${labelPath(data.appLabelArtistDetail)}/artists`}
                      className="labelList-btn"
                      activeClassName="active"
                    >
                      artists
                    </NavLink>
                  </div>

                  <Switch>
                    <Route
                      path={`${labelPath(data.appLabelArtistDetail)}/artists`}
                      render={props => {
                        return <ArtistList artists={artist} />;
                      }}
                    />
                    <Route
                      render={props => {
                        return (
                          <LabelList releases={[...release, ...preReleases]} />
                        );
                      }}
                    />
                  </Switch>
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default LabelPageContainer;
