import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { Router } from 'react-router-dom'
import vanHistory from './history'

import client from "./apollo.config";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import 'normalize.css'
import "./styles/styles.scss";
// import GA from "./utils/GA";


ReactDOM.render(
  <ApolloProvider client={client}>
   <Router history={vanHistory}>
   <>
   {/* { GA.init() }
   <GA.RouteTracker /> */}
      <App />
   </>
   </Router>
  </ApolloProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
