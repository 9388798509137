import React, { Component } from "react";
import queryString from "query-string";

import { Query } from "react-apollo";
import SEARCH from "./searchQuery";
import { LoaderMain } from "../shared/loaders";
import SearchDetails from "./SearchDetails";

class SearchDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const search = queryString.parse(this.props.location.search).q;

    return (
      <Query query={SEARCH} variables={{ searchText: search }}>
        {({ loading, error, data }) => {
          if (loading) return <LoaderMain />;
          if (error) return `Error! ${error.message}`;
          return <SearchDetails results={data.appSearch} />;
        }}
      </Query>
    );
  }
}

export default SearchDetailsContainer;
