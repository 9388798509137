import React from "react";
import { Link } from "react-router-dom";
import { totalTimeFormat } from "../../utils/timeFormat";
import { labelPath } from "../../utils/utils";
import moment from "moment";

const PosBottom = props => {
  const { publicationDate, id, label, tags, isTrackCredits, tracks } = props;
  const releaseDate = moment(publicationDate)
    .utc()
    .format("MM.DD.YYYY");
  const trt = totalTimeFormat(
    tracks &&
      tracks
        .map(t => t.duration)
        .reduce((prevVal, currentVal) => {
          return parseFloat(prevVal) + parseFloat(currentVal);
        })
  );

  return (
    <div className={`posBottom ${isTrackCredits ? "onCredits" : ""}`}>
      <div className="posBottom-trt">
        <span>TRT: {trt}</span>
      </div>

      <div className="posBottom-date">
        <span>released: {releaseDate}</span>
      </div>

      <div className="posBottom-rights" style={{ textAlign: "center" }}>
        <span>© all rights reserved </span>
        {!!label && (
          <Link className="posBottom-link" to={labelPath(label)}>
            {" "}
            {label.userName} >
          </Link>
        )}
      </div>

      <div className="posBottom-id">
        <span>No. {id}</span>
      </div>

      {/* <div className="posBottom-tags">
        {tags &&
          tags.split(", ").map((t, i) => (
            <span key={i} className="posBottom-tag">
              {" "}
              #{t}{" "}
            </span>
          ))}
      </div> */}
    </div>
  );
};

export default PosBottom;
