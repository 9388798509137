export function formatSlug() {
  return [...arguments]
    .filter(e => !!e)
    .join("-")
    .toLowerCase()
    .replace(/\s+/g, "-");
}

export function artistPath(artist) {
  const artistSlug = encodeURIComponent(formatSlug(artist.userName));

  return `/${artistSlug}/${artist.id}`;
}
export function labelPath(label) {
  const labelSlug = encodeURIComponent(formatSlug(label.userName));

  return `/label/${labelSlug}/${label.id}`;
}

export function releasePath(release) {
  const artistSlug = encodeURIComponent(formatSlug(release.artist.userName));
  const albumSlug = encodeURIComponent(formatSlug(release.title));

  return `/${artistSlug}/${release.artist.id}/album/${albumSlug}/${release.id}`;
}

export function releasePathAlt(release, artist) {
  const artistSlug = encodeURIComponent(formatSlug(artist.userName));
  const albumSlug = encodeURIComponent(formatSlug(release.title));

  return `/${artistSlug}/${artist.id}/album/${albumSlug}/${release.id}`;
}

export function trackPath(track) {
  console.log(track);
  const { release } = track;
  const artistSlug = encodeURIComponent(formatSlug(release.artist.userName));
  const albumSlug = encodeURIComponent(formatSlug(release.title));

  return `/${artistSlug}/${release.artist.id}/album/${albumSlug}/${
    release.id
  }/track/${track.id}`;
}

export function trackPathAlt(track, release, artist) {
  const artistSlug = encodeURIComponent(formatSlug(artist.userName));
  const albumSlug = encodeURIComponent(formatSlug(release.title));

  return `/${artistSlug}/${artist.id}/album/${albumSlug}/${release.id}?track=${
    track.id
  }&start=true`;
}

export function getTrackIndex(playlist, trackId) {
  return playlist.map(t => t.id).indexOf(trackId);
}

export function cummulateSupport() {
  let S = [
    {
      amount: 100,
      paymentReceiver: {
        id: "840"
      }
    },
    {
      amount: 100,
      paymentReceiver: {
        id: "840"
      }
    },
    {
      amount: 11,
      paymentReceiver: {
        id: "840"
      }
    }
  ];

  return S;
}
