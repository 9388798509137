import React from "react";
import VeritcalSlider from "../../shared/VerticalSlider";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import { LoaderMain } from "../../shared/loaders";
import { Link } from "react-router-dom";
import {
  createStreamedCollection,
  createSupportedCollection,
  /* createPurchasedCollection */
} from "./createCollections";
import SupportedSlider from "../../shared/SupportedSlider";
// import PurchasedSlider from "../../shared/PurchasedSlider";

const ShowAllLink = ({ linkTo, paddingLeft }) => (
  <Link
    style={{
      fontSize: "12px",
      paddingLeft: paddingLeft || 20,
      color: "#757575"
    }}
    to={`/you/archive/${linkTo}`}
  >
    see all
  </Link>
);

const ARCHIVE_STREAMED = gql`
  query me {
    me {
      id
      playedTracks {
        id
        updatedAt
        track {
          id
          title
          artist
          publicationDate
          about
          additionalAdminInfo
          release {
            id
            title
            cover
            publicationDate
            type
            isAllowed
            artist {
              id
              userName
              firstName
              lastName
            }
          }
        }
      }
      support {
        orignalPrice
        amount
        paymentReceiver {
          id
          userName
          picture
        }
      }
      purchasedTracks {
        id
        createdAt
        track {
          id
          title
          artist
          publicationDate
          about
          additionalAdminInfo
          release {
            id
            title
            cover
            publicationDate
            type
            isAllowed
            artist {
              id
              userName
              firstName
              lastName
            }
          }
        }
      }
      purchasedReleases {
        id
        createdAt
        release {
          id
          title
          cover
          publicationDate
          type
          isAllowed
          artist {
            id
            userName
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const ArchiveMain = () => {
  return (
    <Query query={ARCHIVE_STREAMED} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) return <LoaderMain />;
        if (error) return `Error! ${error.message}`;
        console.log(data);
        return (
          <div className="userArchive-wrapper">
            <div className="page-container">
              <h2
                style={{ marginTop: 0, paddingLeft: "15%" }}
                className="slider-title"
              >
                streamed{" "}
                {data.me.playedTracks.lenght > 0 && (
                  <ShowAllLink linkTo="streamed" />
                )}
              </h2>
              <VeritcalSlider
                collection={createStreamedCollection(data.me.playedTracks)}
              />
              <h2
                style={{ marginTop: 0, paddingLeft: "40%" }}
                className="slider-title"
              >
                supported
                {data.me.support.lenght > 0 && (
                  <ShowAllLink linkTo="supported" paddingLeft="35px" />
                )}
              </h2>
              <SupportedSlider
                collection={createSupportedCollection(data.me.support)}
              />{" "}
              {/* <h2
                style={{ marginTop: 0, paddingLeft: 0, textAlign: "center" }}
                className="slider-title"
              >
                purchased{" "}
                {data.me.purchasedTracks.lenght +
                  data.me.purchasedReleases.lenght >
                  0 && <ShowAllLink linkTo="purchased" paddingLeft="25px" />}
              </h2>
              <PurchasedSlider
                collection={createPurchasedCollection(
                  data.me.purchasedTracks,
                  data.me.purchasedReleases
                )}
              /> */}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default ArchiveMain;
