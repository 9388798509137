import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../home/Home";
import FourByFourContainer from "../4x4/FourByFourContainer";
import NewReleasesContainer from "../newReleases/NewReleasesContainer";
import NewTracksContainer from "../newTracks/NewTracksContainer";
import PosContainer from "../pos/PosContainer";
import LabelPageContainer from "../label/LabelPageContainer";
import ArtistPageContainer from "../artist/ArtistPageContainer";
import UserContainer from "../user/UserContainer";
/* import UserLoginContainer from "../user/login/UserLoginContainer";
import RegisterContainer from "../user/register/RegisterContainer"; */
import UserPage from "../userpage/UserPage";
import UsPageContainer from "../us/UsPageContainer";
import SearchContainer from "../search/SearchContainer";
import Terms from "../terms/Terms";
import PlaybackContainer from "../playback/PlaybackContainer";
import ReleaseCredits from "../credits/ReleaseCredits";
import TrackCreditsContainer from "../credits/TrackCreditsContainer";
import ResetPassword from "../user/resetPassword/ResetPassword";
import DeletedMessage from "../userpage/DeletedMessage";
// import Hls from "hls.js";
import HlsTest from "../hls/hlsTest";
import TrackLyrics from "../credits/Lyrics";

const MainRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/4x4" component={FourByFourContainer} />
      <Route path="/new-releases" component={NewReleasesContainer} />
      <Route path="/new-tracks" component={NewTracksContainer} />
      <Route path="/you" component={UserPage} />
      <Route path="/label/:labelName/:labelId" component={LabelPageContainer} />
      <Route path="/playback/:releaseId" component={PlaybackContainer} />
      <Route path="/login" component={UserContainer} />
      {/* <Route path="/register" component={RegisterContainer} /> */}
      <Route path="/us/terms" component={Terms} />
      <Route path="/us" component={UsPageContainer} />
      <Route path="/search" component={SearchContainer} />
      <Route path="/delete" component={DeletedMessage} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/pos/:releaseId" component={PosContainer} />
      <Route path="/artist/:artistId" component={ArtistPageContainer} />
      <Route path="/hls" component={HlsTest} />
      <Route
        path="/:artistSlug/:artistId/album/:releaseSlug/:releaseId/track/:trackId"
        component={PlaybackContainer}
      />
      <Route
        path="/:artistSlug/:artistId/album/:releaseSlug/:releaseId/credits/tracks/lyrics/:trackId"
        component={TrackLyrics}
      />
      <Route
        path="/:artistSlug/:artistId/album/:releaseSlug/:releaseId/credits/tracks"
        component={TrackCreditsContainer}
      />
      <Route
        path="/:artistSlug/:artistId/album/:releaseSlug/:releaseId/credits"
        component={ReleaseCredits}
      />
      <Route
        path="/:artistSlug/:artistId/album/:releaseSlug/:releaseId"
        component={PosContainer}
      />
      <Route path="/:artistSlug/:artistId" component={ArtistPageContainer} />
    </Switch>
  );
};

export default MainRoutes;
