import React, { useContext } from "react";
import { Subscription, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { AUTH_TOKEN } from "../../constants";
import UserContext from "../../context/UserContext";

const DEL_USER_SUB = gql`
  subscription deleteUserSub {
    deleteUser {
      id
    }
  }
`;

const DeleteUser = props => {
  const user = useContext(UserContext);

  return (
    <Subscription subscription={DEL_USER_SUB}>
      {({ data, loading }) => {
        const { id, client, history } = props;
        
        if (!loading && data) {
         
          if (id === data.deleteUser.id) {
            
            if (props.location.pathname !== "/delete") {
              history.replace("/delete");
              localStorage.removeItem(AUTH_TOKEN);
              client.clearStore();
              user.logout();
            }
          }
        }
        return null;
      }}
    </Subscription>
  );
};

export default withApollo(withRouter(DeleteUser));
