import React from 'react';
import AppPlayer from './AppPlayer';

const AppPlayerContainer = (props) => {
    if(!props.showPlayer) return null
    return ( 
        <AppPlayer {...props}/>
     );
}
 
export default AppPlayerContainer;