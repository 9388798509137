import { Component } from 'react'

class Temp extends Component {
  constructor(props) {
    super(props);

    this.state = {open: true}
  }

  componentDidMount = () => {
    this.timer = setTimeout(() => {
      this.setState({open: false})

      if(this.props.onHide) this.props.onHide()
    }, this.props.timeout * 1000)
  }

  componentWillUnmount = () => {
    clearTimeout(this.timer)
  }

  render() {
    if (this.state.open)
      return this.props.children
    else
      return null
  }
}

Temp.defaultProps = {
  timeout: 3,
}

export default Temp;
