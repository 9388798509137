import { gql } from "apollo-boost";

export const GET_ALERTS = gql`
  query {
    getAlerts {
      id
      type
      artist {
        id
      }
      user {
        id
      }
    }
  }
`;

export const CREATE_ALERT = gql`
mutation createAlert($type: AlertType!, $artistId: ID!) {
  createAlert(type: $type, artistId: $artistId) {
    id
    type
    user {
      id
      userName
    }
    artist {
      id
      userName
    }
    createdAt
    updatedAt
  }
}
`;

export const DELETE_ALERT = gql`
mutation deleteAlert($alertId: ID!) {
  deleteAlert(alertId: $alertId)
}
`;
